/* eslint no-multi-assign: "off" */

import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../models/Root';

type StepsProps = {};

const Steps: React.FC<StepsProps> = observer(() => {
  const { checkout } = useMst();

  // special classnames if on current step
  let shipToClassName: string;
  let deliveryClassName: string;
  let payClassName: string;
  shipToClassName = deliveryClassName = payClassName =
    'w-1/3 py-4 pt-0 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300';
  switch (checkout.step) {
    case 'ShipTo':
      shipToClassName =
        'w-1/3 py-4 pt-0 px-1 text-center border-b-2 border-vol-primary font-medium text-sm leading-5 text-vol-primary';
      break;
    case 'Delivery':
      deliveryClassName =
        'w-1/3 py-4 pt-0 px-1 text-center border-b-2 border-vol-primary font-medium text-sm leading-5 text-vol-primary';
      break;
    case 'Pay':
      payClassName =
        'w-1/3 py-4 pt-0 px-1 text-center border-b-2 border-vol-primary font-medium text-sm leading-5 text-vol-primary';
      break;
    // no default
  }

  return (
    <div>
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            <a
              href="#ShipTo"
              onClick={() => checkout.goToStep('ShipTo')}
              className={shipToClassName}
            >
              Ship To
            </a>
            <a href="#Delivery" className={deliveryClassName}>
              Delivery
            </a>
            <a href="#Pay" className={payClassName}>
              Pay
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
});

export default Steps;
