import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../models/Root';

type ButtonProps = {
  goToStep: string;
};

const Button: React.FC<ButtonProps> = observer((props) => {
  const { checkout } = useMst();

  return (
    <div className="mt-6">
      <button
        className="text-xs"
        type="button"
        onClick={() => checkout.goToStep(props.goToStep)}
      >
        <svg
          className="h-4 w-4 text-gray-500 inline-block mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <polygon
            id="arrow"
            points="3.82842712 9 9.89949494 2.92893219 8.48528137 1.51471863 0 10 0.707106781 10.7071068 8.48528137 18.4852814 9.89949494 17.0710678 3.82842712 11 20 11 20 9 3.82842712 9"
          />
        </svg>
        Back
      </button>
    </div>
  );
});

export default Button;
