import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../models/Root';

type ButtonProps = {
  text: string;
};

const Button: React.FC<ButtonProps> = observer((props) => {
  const { checkout } = useMst();

  return (
    <div className="mt-3 sm:mt-6">
      <span className="block w-full rounded-md shadow-sm">
        <button
          type="submit"
          className="bg-vol-primary w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none transition duration-150 ease-in-out"
        >
          {checkout.state === 'pending' ? (
            <div className="three-dots">
              <span />
              <span />
              <span />
            </div>
          ) : (
            <span>{props.text}</span>
          )}
        </button>
      </span>
    </div>
  );
});

export default Button;
