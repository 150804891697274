/* eslint import/no-cycle: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */
import { types, flow, applySnapshot, getRoot, Instance } from 'mobx-state-tree';
import { IRootStore } from './Root';

export const PaymentMethodModel = types.model({
  id: types.number,
  method: types.string,
  methodType: types.maybeNull(types.string),
});

export const CreditCardBrandModel = types.model({
  id: types.number,
  name: types.maybeNull(types.string),
});

export const State = types.model({
  Id: types.number,
  Code: types.string,
  Name: types.string,
});

export const Country = types.model({
  Id: types.number,
  Name: types.string,
  States: types.array(State),
  StateLabel: types.maybeNull(types.string),
});

export type IConfig = Instance<typeof Config>;
export const Config = types
  .model({
    enabledState: types.maybeNull(types.string),
    logo: types.model({
      text: types.maybeNull(types.string),
      url: types.maybeNull(types.string),
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      publicId: types.maybeNull(types.string),
    }),
    colors: types.model({
      primary: types.string,
    }),
    features: types.model({
      enableGuestCheckout: types.boolean,
      enableOrderComments: types.boolean,
      enableCompanyNameField: types.boolean,
      enableEmailOptIn: types.boolean,
      enableCoupons: types.boolean,
      enableGiftCards: types.boolean,
      enableGiftWrap: types.boolean,
      requireBillToSameAsShipTo: types.boolean,
      enableVolusionLogo: types.boolean,
    }),
    labels: types.model({
      orderComments: types.maybeNull(types.string),
      emailOptIn: types.maybeNull(types.string),
    }),
    analytics: types.model({
      googleAnalyticsId: types.maybeNull(types.string),
    }),
    messages: types.model({
      shipTo: types.maybeNull(types.string),
      delivery: types.maybeNull(types.string),
      pay: types.maybeNull(types.string),
      oneClick: types.maybeNull(types.string),
      thankYou: types.maybeNull(types.string),
      footer: types.maybeNull(types.string),
    }),
    // and more:
    payments: types.model({
      enabledPaymentMethods: types.array(PaymentMethodModel),
      isPaypalExpressAvailable: types.boolean,
      pciUrl: types.string,
      pciMerchantId: types.string,
      enableStoredPaymentProfiles: types.boolean,
    }),
    currencySymbol: types.maybeNull(types.string),
    currentServerDateTime: types.maybeNull(types.string),
    countries: types.array(Country),
  })
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
    get defaultCountry(): string {
      return 'United States';
    },
    get currentStates(): any [] {
      const countryObj = self.countries?.filter((c) => {
        return c.Name === 'United States';
      });
      return countryObj[0]?.States;
    }
  }))
  .actions((self) => {
    const getConfig = flow(function* () {
      console.log('getConfig started');
      const response = yield window.fetch(
        `${self.root.checkout.storeUrl}api/v1/config`,
        {
          credentials: 'include',
        }
      );
      // console.log('getConfig response:', response);
      const responseJsonEnvelope = yield response.json();
      const responseJson = responseJsonEnvelope.data;
      console.log('getConfig responseJson:', responseJson);
      // strip out html from emailOptInLabel
      // const regex = /(<([^>]+)>)/gi;
      // responseJson.emailOptInLabel = responseJson.emailOptInLabel.replace(
      //   regex,
      //   ''
      // );
      applySnapshot(self, responseJson); // disable this line until new config endpoint is live
      //console.log('getConfig finished');
      //console.log('invoke getCountries');
      getCountries();
      //console.log('after getCountries');
    });

    const getCountries = flow(function* () {
      const response = yield window.fetch(
        `${self.root.checkout.storeUrl}api/v1/countries`
      );
      const responseJsonEnvelope = yield response.json();
      const responseJson = responseJsonEnvelope.data;
      // console.log(responseJson, 'getCountries finished');
      applySnapshot(self.countries, responseJson);
    });

    const checkStateAgainstMerchantStates = function (
      shopperInputtedState: string
    ) {
      // see if merchant supports this state
      const country = self.countries.find(
        (o) =>
          o.Name.toLowerCase() ===
          self.root.checkout.shippingAddress.country.toLowerCase()
      );
      const state = country?.States.find(
        (o) =>
          o.Code.toLowerCase() === shopperInputtedState.toLowerCase() ||
          o.Name.toLowerCase() === shopperInputtedState.toLowerCase()
      );
      if (state) {
        // yes, merchant does support this state
        return true;
      }
      // no, merchant does not support this state
      console.log('nope');
      return false;
    };

    return {
      getConfig,
      getCountries,
      checkStateAgainstMerchantStates,
      afterCreate() {
        getConfig();
      },
    };
  });
