import React from 'react';
import { observer } from 'mobx-react-lite';
import { values } from 'mobx';
import { useMst } from '../models/Root';
import Header from './Header';
import Footer from './Footer';
import BackButton from './BackButton';

const ChangePay: React.FC = observer(() => {
  const { checkout } = useMst();

  return (
    <div className="form">
      <Header />
      <BackButton goToStep="OneClick" />

      <dd className="mt-6 text-sm leading-5 text-gray-900 sm:mt-6 sm:col-span-2">
        <div className="text-xs font-medium leading-0 pb-2 text-gray-600">
          Payment
        </div>
        <ul className="border border-gray-200 rounded-md">
          {values(checkout.user.paymentProfiles).map((payment, index) => (
            <li
              key={index}
              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 border-t border-gray-200"
            >
              <div className="flex-shrink-0">
                {`${payment.card.cardTypeName} `}
                {payment.method === 'Credit Card' && (
                  <span>
                    {`**** ${payment.card.last4}`} <br />
                    {payment.card.holdersName}
                  </span>
                )}
              </div>

              <button
                type="button"
                onClick={() => {
                  checkout.paymentProfile.setPaymentProfileWithToken({
                    id: payment.id,
                    method: payment.method,
                    card: {
                      id: payment.card.id,
                      cardNumber: payment.card.cardNumber,
                      pCIaaSId: payment.card.pCIaaSId,
                      holdersName: payment.card.holdersName,
                      expMonth: payment.card.expMonth,
                      expYear: payment.card.expYear,
                      last4: payment.card.last4,
                      cardType: payment.card.cardType,
                      cardTypeName: payment.card.cardTypeName,
                      issueYear: payment.card.issueYear,
                      issueMonth: payment.card.issueMonth,
                      issueNumber: payment.card.issueNumber,
                      cVV: payment.card.cVV,
                    },
                    preferred: payment.preferred,
                  });
                  checkout.goToStep('OneClick');
                }}
                className="flex py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-vol-primary focus:outline-none transition duration-150 ease-in-out"
              >
                Choose
              </button>
            </li>
          ))}
        </ul>
      </dd>
      <div className="mt-6 mb-6 text-sm">
        or{' '}
        <button
          type="submit"
          className="font-medium text-sm text-vol-primary hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
          onClick={() => {
            checkout.setPaymentMethod('Credit Card'); // sets default payment method to show
            checkout.goToStep('AddPay');
          }}
        >
          add a new payment method
        </button>
      </div>
      <Footer />
    </div>
  );
});

export default ChangePay;
