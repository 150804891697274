import React from 'react';
import CardLogo from './CardLogo';

type InputProps = {
  label: string;
  name: string;
  register?: any;
  defaultValue?: any;
  children?: any;
  type?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  autoCapitalize?: string;
  autoCorrect?: string;
  autoComplete?: string;
  pattern?: string;
  noValidate?: boolean;
  errors?: any;
  cardTypeName?: string;
  onChange?: any;
  disabled?: boolean;
  classes?: string
};

const Input = ({
  label,
  children,
  type = 'text',
  name,
  register,
  errors,
  cardTypeName,
  disabled,
  classes,
  ...otherProps
}: InputProps) => (
  <div>
    <div className="mt-2">
      <div className="mt-1 relative rounded-md">
        <label
          htmlFor={name}
          className={`absolute text-xs font-medium leading-2 ml-1 pl-2 mt-1 ${
            errors ? 'text-red-600' : 'text-gray-600'
          }`}
        >
          {label}
        </label>
        <CardLogo cardTypeName={cardTypeName} />
        <input
          className={`appearance-none block w-full p-3 pt-6 pb-1 ml-0 border bg-gray-200 border-transparent rounded-md placeholder-gray-400 focus:outline-none focus:bg-white 
          ${errors ? 'border-red-600' : 'focus:border-blue-300'} 
          ${disabled && 'opacity-50 cursor-not-allowed'} 
          transition duration-150 ease-in-out sm:text-sm sm:leading-5 ${classes}`}
          id={name}
          name={name}
          type={type}
          ref={register}
          disabled={disabled}
          {...otherProps}

        />
      </div>
    </div>
  </div>
);

export default Input;
