import React from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import Interweave from 'interweave';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import Lottie from 'react-lottie';
import { useMst } from '../models/Root';
import animationData from '../lotties/checkmark3.json';
import Input from './Input';
import Button from './Button';

const schema = yup.object().shape({
  password: yup.string().min(6).required(),
});

const Thanks: React.FC = observer(() => {
  const { checkout, config } = useMst();
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });
  const mySubmit = (e: any, functionToRunAfterRHF: any) => {
    e.preventDefault();
    // do your early validation here

    if (checkout.state === 'pending') {
      // wait
    } else {
      handleSubmit((json) => {
        functionToRunAfterRHF(json);
      })(e);
    }
  };

  const lottieOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // load hidden iframe of OrderFinished.asp so that analytics scripts execute as they always would
  const iframeStyle = {
    border: 0,
    height: 1,
    width: 1,
  };

  return (
    <div>
      <div>
        <Lottie options={lottieOptions} height={150} width={200} />
      </div>
      <div className="mt-6 text-center">
        Thank you {checkout.shippingAddress.firstName}! Your Order ID is{' '}
        {checkout.orderId}.
      </div>
      <div className="mt-6 text-center">
        An email confirmation has been sent to{' '}
        <span className="font-medium">{checkout.customer.emailAddress}</span>
      </div>

      {!checkout.customer.isRegistered && (
        <form onSubmit={(e) => mySubmit(e, checkout.addPassword)}>
          <div className="mt-6 text-center">
            Add a password now, for faster checkout next time.
          </div>
          <div className="grid row-gap-0 col-gap-2 grid-cols-6">
            <div className="col-span-6">
              <Input
                label="Password"
                name="password"
                register={register()}
                type="password"
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="new-password"
              />
              <span className="text-red-700">
                {errors.password &&
                  'Password must be at least 6 characters long'}
              </span>
            </div>
          </div>
          <Button text="Add Password" />
        </form>
      )}
      {checkout.customer.justRegistered && (
        <div className="mt-6">
          One click checkout enabled for faster checkout next time.
        </div>
      )}
      <div className="mt-6 text-xs text-center">
        <Interweave content={config.messages.thankYou} />
      </div>
      <div className="mt-10">
        <span className="block w-full rounded-md shadow-sm">
          <a
            href={checkout.storeUrl}
            target="_top"
            rel="noopener noreferrer"
            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
          >
            Shop for more
          </a>
        </span>
      </div>
      <iframe
        src={`${checkout.storeUrl}OrderFinished.asp?Order=Finished&sendemail=true&OrderID=${checkout.orderId}`}
        id="orderFinishedIFrame"
        title="Order Finished iFrame"
        style={iframeStyle}
      />
    </div>
  );
});

export default Thanks;
