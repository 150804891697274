import React from 'react';
import { observer } from 'mobx-react-lite';
import { values } from 'mobx';
import Interweave from 'interweave';
import { useMst } from '../models/Root';

type ErrorsProps = {};

const Errors: React.FC<ErrorsProps> = observer(() => {
  const { checkout } = useMst();
  return (
    <>
      {checkout.state === 'error' && (
        <div className="rounded-md bg-red-100 p-4 mt-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg
                className="h-5 w-5 text-red-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="ml-3">
              <div className="text-sm leading-5 text-red-700">
                {values(checkout.errors).map((error) =>
                  error.messages.map((message: string, index: number) => (
                    <Interweave
                      key={index}
                      content={`<ul class="list-none">${message}</ul>`}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default Errors;
