import React from 'react';
import { observer } from 'mobx-react-lite';
import { CloudinaryContext, Transformation, Image } from 'cloudinary-react';
import { useMst } from '../models/Root';
import Cart from './Cart';

type HeaderProps = {};

const Header: React.FC<HeaderProps> = observer(() => {
  const { config } = useMst();

  // const sampleLogoUrls = [
  //   'junk/t1wfo4675tu6c8ijqsqs',
  //   'junk/sr6mcqhzijx3rbbzhxeh',
  //   'junk/mnkww3a681tyyjjljv8x',
  //   'junk/jzu4n9gdcpjekpmffs6c',
  //   'junk/mwbpqy3qpegp3efyci7y',
  //   'junk/v2amvuddujxw7xelqt4x',
  // ];

  // const random = function (min: number, max: number) {
  //   const theMin = Math.ceil(min);
  //   const theMax = Math.floor(max);
  //   const num = Math.floor(Math.random() * (theMax - theMin + 1)) + theMin;
  //   return num;
  // };

  return (
    <div className="mb-4 sm:mb-6 grid row-gap-0 col-gap-2 grid-cols-6">
      <div className="col-span-4 mr-6">
        {config.logo.publicId ? (
          <CloudinaryContext cloudName="volusion-v1">
            <Image publicId={config.logo.publicId} fetchFormat="auto">
              <Transformation width="auto" height="130" crop="limit" />
              <Transformation effect="trim:1:white" />
            </Image>
          </CloudinaryContext>
        ) : (
          <span className="font-black text-xl sm:text-2xl ml-1">
            {config.logo.text}
          </span>
        )}
      </div>
      <div className="col-span-2">
        <Cart />
      </div>
    </div>
  );
});

export default Header;
