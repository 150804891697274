import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../models/Root';

const Cart: React.FC = observer(() => {
  const { checkout, config } = useMst();

  return (
    <div className="flex flex-row-reverse mt-1">
      {checkout.cartIsExpanded === false ? (
        <div onClick={checkout.expandCart}>
          <button
            type="button"
            className="group text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
          >
            <svg
              className="h-5 w-5 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M3,5 L4.33333333,9 L4,9 C2.34314575,9 1,10.3431458 1,12 C1,13.6568542 2.34314575,15 4,15 L17,15 L17,13 L4.00684547,13 C3.45078007,13 3,12.5561352 3,12 C3,11.4477153 3.44748943,11 3.99850233,11 L10.5,11 L17,11 L20,2 L4,2 L4,0.997030139 C4,0.453036308 3.54809015,0 2.9906311,0 L0,0 L0,2 L2,2 L3,5 Z M5,20 C6.1045695,20 7,19.1045695 7,18 C7,16.8954305 6.1045695,16 5,16 C3.8954305,16 3,16.8954305 3,18 C3,19.1045695 3.8954305,20 5,20 Z M15,20 C16.1045695,20 17,19.1045695 17,18 C17,16.8954305 16.1045695,16 15,16 C13.8954305,16 13,16.8954305 13,18 C13,19.1045695 13.8954305,20 15,20 Z"
                id="cart"
              />
            </svg>
            <span>
              {checkout.items[0].code !== ''
                ? config.currencySymbol +
                  (
                    checkout.totals.items +
                    checkout.totals.discounts +
                    checkout.totals.shipping -
                    checkout.totals.giftCardAmountUsed +
                    checkout.totals.taxTotal
                  ).toFixed(2)
                : 'loading'}
            </span>

            <svg
              className="text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <polygon
                id="chevron-down"
                points="9.29289322 12.9497475 10 13.6568542 15.6568542 8 14.2426407 6.58578644 10 10.8284271 5.75735931 6.58578644 4.34314575 8"
              />{' '}
            </svg>
          </button>
        </div>
      ) : (
        <div onClick={checkout.collapseCart}>
          <button
            type="button"
            className="group text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
          >
            <svg
              className="h-5 w-5 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M3,5 L4.33333333,9 L4,9 C2.34314575,9 1,10.3431458 1,12 C1,13.6568542 2.34314575,15 4,15 L17,15 L17,13 L4.00684547,13 C3.45078007,13 3,12.5561352 3,12 C3,11.4477153 3.44748943,11 3.99850233,11 L10.5,11 L17,11 L20,2 L4,2 L4,0.997030139 C4,0.453036308 3.54809015,0 2.9906311,0 L0,0 L0,2 L2,2 L3,5 Z M5,20 C6.1045695,20 7,19.1045695 7,18 C7,16.8954305 6.1045695,16 5,16 C3.8954305,16 3,16.8954305 3,18 C3,19.1045695 3.8954305,20 5,20 Z M15,20 C16.1045695,20 17,19.1045695 17,18 C17,16.8954305 16.1045695,16 15,16 C13.8954305,16 13,16.8954305 13,18 C13,19.1045695 13.8954305,20 15,20 Z"
                id="cart"
              />
            </svg>
            <span>
              {checkout.items[0].code !== ''
                ? config.currencySymbol +
                  (
                    checkout.totals.items +
                    checkout.totals.discounts +
                    checkout.totals.shipping -
                    checkout.totals.giftCardAmountUsed +
                    checkout.totals.taxTotal
                  ).toFixed(2)
                : 'loading'}
            </span>

            <svg
              className="text-gray-400 h-5 w-5 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <polygon
                id="chevron-up"
                points="10.7071068 7.05025253 10 6.34314575 4.34314575 12 5.75735931 13.4142136 10 9.17157288 14.2426407 13.4142136 15.6568542 12"
              />
            </svg>
          </button>

          <div className="relative">
            <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg">
              <div className="rounded-lg shadow-lg">
                <div className="rounded-lg shadow-xs overflow-hidden">
                  <div className="z-20 relative grid gap-2 bg-white px-5 py-6 sm:gap-2 sm:p-8">
                    {checkout.items.map((item: any) => (
                      <p key={item.id} className="text-xs leading-3">
                        <span className="font-bold">{item.qty} </span>
                        of
                        <span className="font-bold"> {item.name} </span>
                        <span className="text-vol-primary font-bold">
                          {' '}
                          ({config.currencySymbol}
                          {item.pricing.unitPrice.toFixed(2)} ea)
                        </span>
                      </p>
                    ))}

                    {checkout.discounts.map((discount: any) => (
                      <p key={discount.id} className="text-xs leading-3">
                        <span className="font-bold">{discount.name} </span>
                        <span className="text-vol-primary font-bold">
                          {' '}
                          ({config.currencySymbol}
                          {discount.value.toFixed(2)})
                        </span>
                      </p>
                    ))}

                    {checkout.totals.giftCardAmountUsed > 0 && (
                      <p className="text-xs leading-3">
                        <span>
                          {'- '}
                          {config.currencySymbol}
                          {checkout.totals.giftCardAmountUsed.toFixed(2)} gift
                          card applied 🎁
                        </span>
                      </p>
                    )}

                    <p className="text-xs leading-3 mt-3">
                      Subtotal: {config.currencySymbol}
                      {(
                        checkout.totals.items +
                        checkout.totals.discounts +
                        checkout.totals.giftCardAmountUsed
                      ).toFixed(2)}
                    </p>

                    {(checkout.step === 'Pay' ||
                      checkout.step === 'Delivery' ||
                      checkout.totals.taxTotal > 0) && (
                      <p className="text-xs leading-3">
                        Tax:
                        <span>
                          {' '}
                          {config.currencySymbol}
                          {checkout.totals.taxTotal.toFixed(2)}
                        </span>
                      </p>
                    )}

                    {checkout.step === 'Pay' && (
                      <p className="text-xs leading-3">
                        Shipping: {config.currencySymbol}
                        {checkout.totals.shipping.toFixed(2)}
                      </p>
                    )}

                    <p className="text-xs leading-3 font-bold">
                      Grand Total: {config.currencySymbol}
                      {(
                        checkout.totals.items +
                        checkout.totals.discounts +
                        checkout.totals.shipping -
                        checkout.totals.giftCardAmountUsed +
                        checkout.totals.taxTotal
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default Cart;
