import React from 'react';
import { observer } from 'mobx-react-lite';
import { values } from 'mobx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import valid from 'card-validator';
import { useMst } from '../models/Root';
import Input from './Input';
import Select from './Select';
import Header from './Header';
import Button from './Button';
import Footer from './Footer';
import BackButton from './BackButton';
import PlacesAutocomplete from './PlacesAutocomplete';
import Errors from './Errors';

const AddPay: React.FC = observer(() => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const { checkout, config, tokenization } = useMst();

  const validateCreditCardNumber = function (value: string) {
    return valid.number(value).isValid;
  };

  const billingAddressSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    address1: yup.string().required(),
    address2: yup.string().optional(),
    city: yup.string().required(),
    state: yup.string().required(),
    postalCode: yup.string().required(),
    country: yup.string().required(),
  });
  const getBillingAddressSchema: any = checkout.billToSame
    ? null
    : billingAddressSchema;

  const creditCardSchema = yup.object().shape({
    FullCardNumber: yup
      .string()
      .test(
        '',
        'Credit Card number is invalid', // validation message
        (value) => validateCreditCardNumber(value)
      ) // return true false based on validation
      .required(),
    CardHolderName: yup.string().required(),
    ExpireMonth: yup.string().required(),
    ExpireYear: yup.string().required(),
    CVV: yup
      .string()
      .test(
        '',
        'CVV input is invalid', // validation message
        (value) => valid.cvv(value).isValid
      ) // return true false based on validation
      .required(),
  });
  const getCreditCardSchema: any =
    checkout.paymentProfile.method === 'Credit Card' ? creditCardSchema : null;

  const payScreenSchema = yup.object().shape({
    tokenization: getCreditCardSchema,
    checkout: yup.object().shape({
      billingAddress: getBillingAddressSchema,
    }),
  });

  const schema = payScreenSchema;

  const checkCardType = function (value: string) {
    const result = valid.number(value);
    if (result.card?.type) {
      console.log('card type:', result.card.type);
      // see if merchant supports this card type
      const brand = config.payments.enabledPaymentMethods.find(
        (o) => o.method.toLowerCase() === result?.card?.niceType.toLowerCase()
      );
      if (brand) {
        // yes, merchant does support this type
        console.log('brand:', brand);
        console.log('brand id:', brand.id);
        tokenization.updateCardType({
          CardType: brand.id,
          CardTypeName: result.card.niceType,
        });
        checkout.setErrors([]);
      } else {
        // no, merchant does not support this type
        checkout.setErrors([
          {
            field: 'Credit Card Number',
            messages: [`Sorry, we do not accept ${result?.card?.niceType}`],
          },
        ]);
        tokenization.updateCardType({
          CardType: '',
          CardTypeName: '',
        });
      }
    }
  };

  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handlePaymentMethodOnChange = (e: any) => {
    console.log('e.target.value', e.target.value);
    checkout.setPaymentMethod(e.target.value);
  };
  const handleBillToSameCheckboxOnChange = (e: any) => {
    console.log('e.target.checked', e.target.checked);
    if (e.target.checked) {
      // set billing address to match shipping address
      checkout.updateBillToSame(true);
      console.log('set billing address to match shipping address');
    } else {
      // clear billing address and let shopper enter one
      checkout.updateBillToSame(false);
      console.log('clear billing address and let shopper enter one');
    }
  };

  const mySubmit = (e: any, functionToRunAfterRHF: any) => {
    e.preventDefault();
    // do your early validation here

    if (checkout.state === 'pending') {
      // wait
    } else {
      handleSubmit((json) => {
        functionToRunAfterRHF(json);
      })(e);
    }
  };

  return (
    <div className="form">
      <Header />
      <BackButton goToStep="OneClick" />

      <div className="mt-6 text-sm font-bold">Add New Payment Method</div>

      <Errors />

      <form onSubmit={(e) => mySubmit(e, checkout.addPay)}>
        <div className="mt-3 grid row-gap-0 col-gap-2 grid-cols-6">
          <div className="col-span-6">
            <Select
              label="Payment Method"
              name="checkout.paymentProfile.method"
              options={values(config.payments.enabledPaymentMethods).map(
                function (method) {
                  return {
                    name: method.methodType,
                  };
                }
              )}
              defaultValue={checkout.paymentProfile.method}
              register={register()}
              onChange={handlePaymentMethodOnChange}
              errors={errors.checkout?.paymentProfile?.method}
            />
          </div>
          {checkout.paymentProfile.method === 'Credit Card' && (
            <>
              <div className="col-span-6">
                <Input
                  label="Card Number"
                  name="tokenization.FullCardNumber"
                  register={register()}
                  errors={errors.tokenization?.FullCardNumber}
                  type="text"
                  inputMode="decimal"
                  noValidate
                  autoCorrect="off"
                  autoComplete="cc-number"
                  onChange={(e: any) => checkCardType(e.target.value)}
                  cardTypeName={tokenization.CardTypeName}
                />
              </div>
              <div className="col-span-6">
                <Input
                  label="Name on card"
                  name="tokenization.CardHolderName"
                  register={register()}
                  errors={errors.tokenization?.CardHolderName}
                  type="text"
                  inputMode="text"
                  autoCorrect="off"
                  autoComplete="cc-name"
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="Expire Month"
                  name="tokenization.ExpireMonth"
                  register={register()}
                  errors={errors.tokenization?.ExpireMonth}
                  type="text"
                  inputMode="decimal"
                  pattern="[0-9]*"
                  noValidate
                  autoCorrect="off"
                  autoComplete="cc-exp-month"
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="Expire Year"
                  name="tokenization.ExpireYear"
                  register={register()}
                  errors={errors.tokenization?.ExpireYear}
                  type="text"
                  inputMode="decimal"
                  pattern="[0-9]*"
                  noValidate
                  autoCorrect="off"
                  autoComplete="cc-exp-year"
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="CVV"
                  name="tokenization.CVV"
                  register={register()}
                  errors={errors.tokenization?.CVV}
                  type="text"
                  inputMode="decimal"
                  noValidate
                  autoCorrect="off"
                  autoComplete="cc-csc"
                />
              </div>
            </>
          )}
          <div className="col-span-6">
            <div className="flex sm:items-center mt-4">
              <input
                id="checkout.customer.billToSame"
                name="checkout.customer.billToSame"
                type="checkbox"
                ref={register}
                onChange={handleBillToSameCheckboxOnChange}
                className="form-checkbox h-4 w-4 mt-1 sm:mt-0 text-vol-primary transition duration-150 ease-in-out"
                defaultChecked
              />
              <label
                htmlFor="checkout.customer.billToSame"
                className="ml-2 block text-xs leading-6 sm:leading-5 text-gray-900"
              >
                Credit card billing address zip is{' '}
                <span className="font-medium">
                  {checkout.shippingAddress.postalCode}
                </span>
              </label>
            </div>
          </div>
          {!checkout.billToSame && (
            <>
              <div className="mt-6 col-span-6 text-xs text-gray-900">
                Billing Address
              </div>

              <div className="col-span-3">
                <Input
                  label="First name"
                  name="checkout.billingAddress.firstName"
                  defaultValue={checkout.billingAddress.firstName}
                  register={register()}
                  errors={errors.checkout?.billingAddress?.firstName}
                  type="text"
                  autoCorrect="off"
                  autoComplete="given-name"
                />
              </div>
              <div className="col-span-3">
                <Input
                  label="Last name"
                  name="checkout.billingAddress.lastName"
                  defaultValue={checkout.billingAddress.lastName}
                  register={register()}
                  errors={errors.checkout?.billingAddress?.lastName}
                  type="text"
                  autoCorrect="off"
                  autoComplete="family-name"
                />
              </div>
              <div className="col-span-6 sm:col-span-4">
                <PlacesAutocomplete
                  label="Address"
                  name="checkout.billingAddress.address1"
                  defaultValue={checkout.billingAddress.address1}
                  register={register()}
                  errors={errors.checkout?.billingAddress?.address1}
                  type="text"
                  autoCorrect="off"
                  autoComplete="address-line1"
                />
              </div>
              {!isMobile && (
                <div className="col-span-6 sm:col-span-2">
                  <Select
                    label="Country"
                    name="checkout.billingAddress.country"
                    options={values(config.countries).map(function (country) {
                      return {
                        name: country.Name,
                      };
                    })}
                    defaultValue={checkout.billingAddress.country}
                    register={register()}
                    errors={errors.checkout?.billingAddress?.country}
                    autoComplete="country"
                  />
                </div>
              )}
              <div className="col-span-6">
                <Input
                  label="Apartment, suite, etc. (optional)"
                  name="checkout.billingAddress.address2"
                  defaultValue={checkout.billingAddress.address2}
                  register={register()}
                  errors={errors.checkout?.billingAddress?.address2}
                  type="text"
                  autoCorrect="off"
                  autoComplete="address-line2"
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="City"
                  name="checkout.billingAddress.city"
                  defaultValue={checkout.billingAddress.city}
                  register={register()}
                  errors={errors.checkout?.billingAddress?.city}
                  type="text"
                  autoCorrect="off"
                  autoComplete="address-level2"
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="State"
                  name="checkout.billingAddress.state"
                  defaultValue={checkout.billingAddress.state}
                  register={register()}
                  errors={errors.checkout?.billingAddress?.state}
                  type="text"
                  autoCorrect="off"
                  autoComplete="address-level1"
                />
              </div>
              <div className="col-span-2">
                <Input
                  label="Zip"
                  name="checkout.billingAddress.postalCode"
                  defaultValue={checkout.billingAddress.postalCode}
                  register={register()}
                  errors={errors.checkout?.billingAddress?.postalCode}
                  type="text"
                  autoCorrect="off"
                  autoComplete="postal-code"
                />
              </div>

              {isMobile && (
                <div className="col-span-6">
                  <Select
                    label="Country"
                    name="checkout.billingAddress.country"
                    options={values(config.countries).map(function (country) {
                      return {
                        name: country.Name,
                      };
                    })}
                    defaultValue={checkout.billingAddress.country}
                    register={register()}
                    errors={errors.checkout?.billingAddress?.country}
                    autoComplete="country"
                  />
                </div>
              )}
            </>
          )}
        </div>

        <Button text="Add payment method" />

        <Footer />
      </form>
    </div>
  );
});

export default AddPay;
