import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../models/Root';
import Empty from './Empty';
import ShipTo from './ShipTo';
import Delivery from './Delivery';
import Pay from './Pay';
import OneClick from './OneClick';
import ChangeShipTo from './ChangeShipTo';
import ChangeBillTo from './ChangeBillTo';
import ChangePay from './ChangePay';
import AddShipTo from './AddShipTo';
import AddBillTo from './AddBillTo';
import AddPay from './AddPay';
import Thanks from './Thanks';

const Checkout: React.FC = observer(() => {
  const { config, checkout } = useMst();

  // Add dynamic css color overrides
  // todo: future refactor here, there's likely a better way to handle this
  const hexToRGB = function hexToRGB(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
  };
  const externalCssLink = document.createElement('style');
  externalCssLink.innerHTML = `
    .bg-vol-primary { background-color: ${config.colors.primary} }
    .bg-vol-primary:hover { background-color: ${hexToRGB(
      config.colors.primary,
      0.8
    )} }
    .text-vol-primary { color: ${config.colors.primary} }
    .text-vol-primary:hover { color: ${hexToRGB(config.colors.primary, 0.8)} }
    .border-vol-primary { border-color: ${config.colors.primary} }
    .checkmark { stroke: ${config.colors.primary} }
  `;
  document.querySelector('head')?.appendChild(externalCssLink);

  switch (checkout.step) {
    case 'Empty':
      return <Empty />;
    case 'ShipTo':
      return <ShipTo />;
    case 'Delivery':
      return <Delivery />;
    case 'Pay':
      return <Pay />;
    case 'OneClick':
      return <OneClick />;
    case 'ChangeShipTo':
      return <ChangeShipTo />;
    case 'ChangeBillTo':
      return <ChangeBillTo />;
    case 'ChangePay':
      return <ChangePay />;
    case 'AddShipTo':
      return <AddShipTo />;
    case 'AddBillTo':
      return <AddBillTo />;
    case 'AddPay':
      return <AddPay />;
    case 'Thanks':
      return <Thanks />;
    default:
      return null;
  }
});

export default Checkout;
