import React from 'react';

type TextareaProps = {
  label: string;
  name: string;
  register?: any;
  errors?: any;
  onChange?: any;
  disabled?: boolean;
  classes?: string;
  height?: number;
  width?: number;
};

const Textarea = ({
  label,
  name,
  register,
  errors,
  onChange,
  disabled,
  classes,
  height = 5,
  width = 50,
  ...otherProps
}: TextareaProps) => {
    return (
        <div className="textarea">
            <div className="mt-2">
            <div className="mt-1 relative rounded-md">
                <label
                htmlFor={name}
                className={`absolute text-xs font-medium leading-2 ml-1 pl-2 mt-1 ${
                    errors ? 'text-red-600' : 'text-gray-600'
                }`}
                >
                {label}
                </label>
                <textarea
                    id={name}
                    className={`${classes} appearance-none block w-full p-3 pt-6 pb-1 ml-0 border bg-gray-200 border-transparent rounded-md placeholder-gray-400 focus:outline-none focus:bg-white 
                    ${errors ? 'border-red-600' : 'focus:border-blue-300'} 
                    ${disabled && 'opacity-50 cursor-not-allowed'} 
                    transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                    rows={height}
                    cols={width}
                    name={name}
                    ref={register}
                    disabled={disabled}
                    onChange={onChange}
                    {...otherProps}
                />
            </div>
            </div>
        </div>
    );
}

export default Textarea;
