import React from 'react';
import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';
import { Provider, rootStore } from './models/Root';
import Checkout from './components/Checkout';
import ErrorPage from './components/ErrorPage';

Sentry.init({
  dsn:
    'https://b818ad7ad6f64b9e9f797a11c7b0d0b4@o416762.ingest.sentry.io/5313094',
});

FullStory.init({
  orgId: 'WF592',
  recordOnlyThisIFrame: true,
});

const App: React.FC = () => {
  return (
    <Provider value={rootStore}>
      <div className="relative bg-gray-50 overflow-hidden">
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-lg lg:max-w-xl xl:max-w-2xl">
            <div className="checkout-modal-styles min-h-screen sm:min-h-0 bg-white py-4 sm:py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <Sentry.ErrorBoundary fallback={ErrorPage} showDialog>
                <Checkout />
              </Sentry.ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default App;
