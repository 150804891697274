import React from 'react';
import { observer } from 'mobx-react-lite';
import { values } from 'mobx';
import { useForm } from 'react-hook-form';
import Interweave from 'interweave';
import { useMst } from '../models/Root';
import Header from './Header';
import Steps from './Steps';
import Button from './Button';
import Footer from './Footer';

const Delivery: React.FC = observer(() => {
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const mySubmit = (e: any, checkout: any, functionToRunAfterRHF: any) => {
    e.preventDefault();
    // do your early validation here

    if (checkout.state === 'pending') {
      // wait
    } else {
      handleSubmit((json) => {
        functionToRunAfterRHF(json);
      })(e);
    }
  };
  const { checkout, config } = useMst();

  return (
    <div className="form">
      <Header />
      <Steps />

      <form onSubmit={(e) => mySubmit(e, checkout, checkout.updateDelivery)}>
        {/* display the rates */}

        {checkout.state === 'pending' && (
          <div className="mt-12 mb-12 w-full rounded-md flex justify-center py-2 px-4 text-sm font-sm">
            loading...
          </div>
        )}

        {checkout.state !== 'pending' && (
          <dd className="mt-6 text-sm leading-5 text-gray-900 sm:mt-6 sm:col-span-2">
            <div className="text-xs">
              <Interweave content={config.messages.delivery} />
            </div>

            <ul className="mt-6 border border-gray-200 rounded-md">
              {values(checkout.shippingMethods).map((method, index) => (
                <div key={method.id}>
                  <li
                    className={
                      index === 0
                        ? 'pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5'
                        : 'border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5'
                    }
                  >
                    <div className="w-0 flex-1 flex items-center">
                      <input
                        type="radio"
                        name="selectedShippingMethod.id"
                        ref={register({ required: true })}
                        id={(method.id as unknown) as string}
                        value={method.id}
                        defaultChecked={method.selected}
                        className="form-radio"
                      />
                      <label
                        htmlFor={(method.id as unknown) as string}
                        className="ml-3 flex-1 w-0 font-medium truncate hover:text-blue-700 transition duration-150 ease-in-out"
                      >
                        {method.name}
                      </label>
                    </div>
                    <div className="ml-4 flex-shrink-0 font-medium">
                      {config.currencySymbol}
                      {method.price.toFixed(2)}
                    </div>
                  </li>
                </div>
              ))}
            </ul>
          </dd>
        )}
        <div className="mt-3 text-red-700">
          {errors?.selectedShippingMethod?.id &&
            'Please select a shipping method to continue'}
        </div>

        <Button text="Next" />

        <Footer />
      </form>
    </div>
  );
});

export default Delivery;
