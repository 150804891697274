import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { values } from 'mobx';
import { useForm } from 'react-hook-form';
import Interweave from 'interweave';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import { debounce } from 'lodash';
import { useMst } from '../models/Root';
import Input from './Input';
import Select from './Select';
import Header from './Header';
import Steps from './Steps';
import Button from './Button';
import Footer from './Footer';
import PlacesAutocomplete from './PlacesAutocomplete';

const ShipTo: React.FC = observer(() => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const { checkout, config } = useMst();

  const schema = yup.object().shape({
    checkout: yup.object().shape({
      customer: yup.object().shape({
        emailAddress: yup.string().email().required(),
      }),
      shippingAddress: yup.object().shape({
        phoneNumber: yup.string().required().min(10).max(24),
        firstName: yup.string().required().max(50),
        lastName: yup.string().required().max(50),
        address1: yup.string().required().max(95),
        address2: yup.string().optional().max(95),
        city: yup.string().required().max(30),
        state: yup
          .string()
          .test('', 'sorry we do not ship to that state', (value) =>
            config.checkStateAgainstMerchantStates(value)
          )
          .required(),
        postalCode: yup.string().required().min(5).max(12),
        country: yup.string().required(),
      }),
    }),
  });

  const loginSchema = yup.object().shape({
    password: yup.string().required(),
  });

  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });
  const mySubmit = (e: any, functionToRunAfterRHF: any) => {
    e.preventDefault();
    // do your early validation here

    if (checkout.state === 'pending') {
      // wait
    } else {
      handleSubmit((json) => {
        functionToRunAfterRHF(json);
      })(e);
    }
  };

  const { register: loginRegister, handleSubmit: loginHandleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(loginSchema),
  });
  const loginSubmit = (e: any, functionToRunAfterRHF: any) => {
    e.preventDefault();
    // do your early validation here

    if (checkout.state === 'pending') {
      // wait
    } else {
      loginHandleSubmit((json) => {
        functionToRunAfterRHF(json);
      })(e);
    }
  };

  const checkEmail = debounce((email) => {
    checkout.checkIfEmailHasRegisteredAccount(email);
  }, 500);

  const [isCountryUS, setIsCountryUS] = useState(true);

  const handleCountrySelect = (event: any) => {
    if (event.target.value !== config.defaultCountry) {
      setIsCountryUS(false);
    } else {
      setIsCountryUS(true);
    }
  }

  return (
    <div className="form">
      <Header />
      <Steps />

      {checkout.state === 'login' && (
        <form onSubmit={(e) => loginSubmit(e, checkout.login)}>
          <div className="mt-6 py-4 px-4 text-xs shadow rounded-lg">
            <div>
              Hey, welcome back
              {checkout.customer.emailAddress && (
                <b> {checkout.customer.emailAddress}</b>
              )}
              . If you know your password we can pre-fill this form. Otherwise,
              don&apos;t sweat it, our guest checkout is a breeze.
            </div>
            {checkout.loginError && (
              <div className="rounded-md bg-red-100 p-4 mt-3">
                <div className="text-sm leading-5 text-red-700">
                  {checkout.loginError}
                </div>
              </div>
            )}
            <div className="grid row-gap-0 col-gap-2 grid-cols-6">
              {!checkout.customer.emailAddress && (
                <div className="col-span-6">
                  <Input
                    label="Email"
                    name="email"
                    defaultValue={checkout.customer.emailAddress}
                    register={loginRegister()}
                    errors={errors.email}
                    type="email"
                    inputMode="email"
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="email"
                  />
                </div>
              )}
              <div className="col-span-6">
                <Input
                  label="Password"
                  name="password"
                  register={loginRegister()}
                  type="password"
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoComplete="current-password"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-6 gap-3">
              <div className="col-span-3">
                <span className="w-full inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                    onClick={() => checkout.setState('')}
                  >
                    No Thanks
                  </button>
                </span>
              </div>

              <div className="col-span-3">
                <span className="block w-full rounded-md shadow-sm">
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-vol-primary focus:outline-none transition duration-150 ease-in-out"
                  >
                    Sign in
                  </button>
                </span>
              </div>
            </div>
          </div>
        </form>
      )}

      {checkout.state === 'notDeliverable' && (
        <div className="rounded-md bg-yellow-100 p-4 mt-3">
          <div className="text-xs leading-5 text-yellow-800">
            {checkout.addressError}
          </div>
        </div>
      )}

      <form onSubmit={(e) => mySubmit(e, checkout.updateShipTo)}>
        <div className="mt-6 text-xs">
          <Interweave content={config.messages.shipTo} />
        </div>

        <div className="text-xs mt-6">
          {checkout.customer.id > 0 ? (
            <span className="text-xs">
              Welcome back <b>{checkout.customer.emailAddress}</b>. Please fill
              out the form below to continue.
            </span>
          ) : (
            <>
              <span className="text-xs">Returning customer?</span>{' '}
              <button
                type="button"
                className="font-medium text-vol-primary hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                onClick={() => checkout.setState('login')}
              >
                Sign In
              </button>
            </>
          )}
        </div>
        <div className="mt-3 grid row-gap-0 col-gap-2 grid-cols-6">
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Email"
              name="checkout.customer.emailAddress"
              defaultValue={checkout.customer.emailAddress}
              register={register()}
              errors={errors.checkout?.customer?.emailAddress}
              type="email"
              inputMode="email"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="email"
              disabled={checkout.customer.isLoggedIn}
              onChange={(e: any) => checkEmail(e.target.value)}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              label="Phone"
              name="checkout.shippingAddress.phoneNumber"
              defaultValue={checkout.shippingAddress.phoneNumber}
              register={register()}
              errors={errors.checkout?.shippingAddress?.phoneNumber}
              type="tel"
              inputMode="tel"
              autoCorrect="off"
              autoComplete="tel"
            />
          </div>
          <div className="col-span-3">
            <Input
              label="First name"
              name="checkout.shippingAddress.firstName"
              defaultValue={checkout.shippingAddress.firstName}
              register={register()}
              errors={errors.checkout?.shippingAddress?.firstName}
              type="text"
              autoCorrect="off"
              autoComplete="given-name"
            />
          </div>
          <div className="col-span-3">
            <Input
              label="Last name"
              name="checkout.shippingAddress.lastName"
              defaultValue={checkout.shippingAddress.lastName}
              register={register()}
              errors={errors.checkout?.shippingAddress?.lastName}
              type="text"
              autoCorrect="off"
              autoComplete="family-name"
            />
          </div>
          <div className="col-span-6 sm:col-span-4">
            <PlacesAutocomplete
              label="Address"
              name="checkout.shippingAddress.address1"
              defaultValue={checkout.shippingAddress.address1}
              register={register()}
              errors={errors.checkout?.shippingAddress?.address1}
              type="text"
              autoCorrect="off"
              autoComplete="address-line1"
            />
          </div>
          {!isMobile && (
            <div className="col-span-6 sm:col-span-2">
              <Select
                label="Country"
                name="checkout.shippingAddress.country"
                options={config?.countries.map(function (country) {
                  return {
                    name: country.Name,
                  };
                })}
                defaultValue={config.defaultCountry}
                register={register()}
                errors={errors.checkout?.shippingAddress?.country}
                autoComplete="country"
                onChange={handleCountrySelect}
              />
            </div>
          )}
          <div className="col-span-6">
            <Input
              label="Apartment, suite, etc. (optional)"
              name="checkout.shippingAddress.address2"
              defaultValue={checkout.shippingAddress.address2}
              register={register()}
              errors={errors.checkout?.shippingAddress?.address2}
              type="text"
              autoCorrect="off"
              autoComplete="address-line2"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="City"
              name="checkout.shippingAddress.city"
              defaultValue={checkout.shippingAddress.city}
              register={register()}
              errors={errors.checkout?.shippingAddress?.city}
              type="text"
              autoCorrect="off"
              autoComplete="address-level2"
            />
          </div>
          <div className="col-span-2">
            {
              isCountryUS ?
              (<Select
                label="State"
                name="checkout.shippingAddress.state"
                options={config?.currentStates?.map(function (state: any) {
                  return {
                    name: state.Code,
                  };
                })}
                register={register()}
                errors={errors.checkout?.shippingAddress?.state}
                autoComplete="state"
             />) : (
                <Input
                  label="State"
                  name="state"
                  register={register()}
                  errors={errors.checkout?.shippingAddress?.state}
                  autoComplete="state"
              />
             )
            }

          </div>
          <div className="col-span-2">
            <Input
              label="Zip"
              name="checkout.shippingAddress.postalCode"
              defaultValue={checkout.shippingAddress.postalCode}
              register={register()}
              errors={errors.checkout?.shippingAddress?.postalCode}
              type="text"
              autoCorrect="off"
              autoComplete="postal-code"
            />
          </div>
          {isMobile && (
            <div className="col-span-6">
              <Select
                label="Country"
                name="checkout.shippingAddress.country"
                options={values(config.countries).map(function (country) {
                  return {
                    name: country.Name,
                  };
                })}
                defaultValue={checkout.shippingAddress.country}
                register={register()}
                errors={errors.checkout?.shippingAddress?.country}
                autoComplete="country"
              />
            </div>
          )}
          {config.features.enableEmailOptIn && (
            <div className="col-span-6">
              <div className="flex sm:items-center mt-4">
                <input
                  id="checkout.customer.emailOptIn"
                  name="checkout.customer.emailOptIn"
                  type="checkbox"
                  ref={register}
                  className="form-checkbox h-4 w-4 mt-1 sm:mt-0 text-vol-primary transition duration-150 ease-in-out"
                  defaultChecked={checkout.customer.emailOptIn}
                />
                <label
                  htmlFor="checkout.customer.emailOptIn"
                  className="ml-2 block text-xs leading-6 sm:leading-5 text-gray-900"
                >
                  {config.labels.emailOptIn}
                </label>
              </div>
            </div>
          )}
        </div>

        <Button text="Next" />
        <Footer />
      </form>
    </div>
  );
});

export default ShipTo;
