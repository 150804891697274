import React from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import Interweave from 'interweave';
import { values } from 'mobx';
import { useMst } from '../models/Root';
import Header from './Header';
import Button from './Button';
import Footer from './Footer';
import Errors from './Errors';

const OneClick: React.FC = observer(() => {
  const { checkout, config } = useMst();

  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const mySubmit = (e: any, functionToRunAfterRHF: any) => {
    e.preventDefault();
    // do your early validation here

    if (checkout.state === 'pending') {
      // wait
    } else {
      handleSubmit((json) => {
        functionToRunAfterRHF(json);
      })(e);
    }
  };

  return (
    <div className="form">
      <Header />
      <h1 className="text-center font-medium text-base">One Click Checkout</h1>
      <div className="text-center">
        {' '}
        <a
          href="#ShipTo"
          onClick={() => checkout.startNewCheckout()}
          className="text-xs underline"
        >
          or start new checkout
        </a>
      </div>

      <Errors />

      <form onSubmit={(e) => mySubmit(e, checkout.submitOneClick)}>
        <div className="mt-6 text-xs">
          <Interweave content={config.messages.oneClick} />
        </div>

        {/* display the contact and shipto - BEGIN */}
        <dd className="mt-6 text-sm leading-5 text-gray-900 sm:mt-6 sm:col-span-2">
          <div className="text-xs font-medium leading-0 pb-2 text-gray-600">
            Ship To
          </div>
          <ul className="border border-gray-200 rounded-md">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 border-gray-200">
              <div className="w-0 flex-1 flex items-center">
                {checkout.customer.emailAddress}
              </div>
              {/* <a
                href="#ShipTo"
                onClick={() => checkout.goToStep("ShipTo")}
                className="ml-4 flex-shrink-0 text-xs underline"
              >
                Change
              </a> */}
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 border-t border-gray-200">
              <div className="flex-shrink-0">
                {`${checkout.shippingAddress.firstName} ${checkout.shippingAddress.lastName}`}
                <br />
                {checkout.shippingAddress.address1} <br />
                {checkout.shippingAddress.address2 && (
                  <>
                    {checkout.shippingAddress.address2} <br />
                  </>
                )}
                {`${checkout.shippingAddress.city}, ${checkout.shippingAddress.state} ${checkout.shippingAddress.postalCode}`}{' '}
                <br />
                {checkout.shippingAddress.country}
              </div>
              <a
                href="#ChangeShipTo"
                onClick={() => checkout.goToStep('ChangeShipTo')}
                className="ml-4 flex-shrink-0 text-xs underline"
              >
                Change
              </a>
            </li>
          </ul>
        </dd>
        {/* display the contact and shipto - END */}
        {/* display the rates - BEGIN */}

        {checkout.state !== 'pending' ||
        checkout?.selectedShippingMethod?.id ? (
          <dd className="mt-6 text-sm leading-5 text-gray-900 sm:mt-6 sm:col-span-2">
            <div className="text-xs font-medium leading-0 pb-2 text-gray-600">
              Delivery
            </div>
            <ul className="border border-gray-200 rounded-md">
              {values(checkout.shippingMethods).map((method, index) => (
                <div key={method.id}>
                  <li
                    className={
                      index === 0
                        ? 'pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5'
                        : 'border-t border-gray-200 pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5'
                    }
                  >
                    <div className="w-0 flex-1 flex items-center">
                      <input
                        type="radio"
                        name="selectedShippingMethod.id"
                        ref={register({ required: true })}
                        id={(method.id as unknown) as string}
                        value={method.id}
                        onClick={() =>
                          checkout.updateDelivery({
                            selectedShippingMethod: { id: method.id },
                          })
                        }
                        defaultChecked={method.selected}
                        className="form-radio"
                      />
                      <label
                        htmlFor={(method.id as unknown) as string}
                        className="ml-3 flex-1 w-0 font-medium truncate hover:text-blue-700 transition duration-150 ease-in-out"
                      >
                        {method.name}
                      </label>
                    </div>
                    <div className="ml-4 flex-shrink-0 font-medium">
                      {config.currencySymbol}
                      {method.price.toFixed(2)}
                    </div>
                  </li>
                </div>
              ))}
            </ul>
          </dd>
        ) : (
          <div className="mt-12 mb-12 w-full rounded-md flex justify-center py-2 px-4 text-sm font-sm">
            loading...
          </div>
        )}

        {/* display the rates - END */}
        {/* display the payment method - BEGIN */}
        <dd className="mt-6 text-sm leading-5 text-gray-900 sm:mt-6 sm:col-span-2">
          <div className="text-xs font-medium leading-0 pb-2 text-gray-600">
            Payment
          </div>
          <ul className="border border-gray-200 rounded-md">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 border-gray-200">
              <div className="flex-shrink-0">
                {`${checkout.paymentProfile.card.cardTypeName} `}
                {checkout.paymentProfile.method === 'Credit Card' && (
                  <span>
                    {`**** ${checkout.paymentProfile.card.last4}`} <br />
                    {checkout.paymentProfile.card.holdersName}
                  </span>
                )}
              </div>
              <a
                href="#ChangePay"
                onClick={() => checkout.goToStep('ChangePay')}
                className="ml-4 flex-shrink-0 text-xs underline"
              >
                Change
              </a>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 border-t border-gray-200">
              <div className="flex-shrink-0">
                Billing Zip {checkout.billingAddress.postalCode}
              </div>
              <a
                href="#ChangeBillTo"
                onClick={() => checkout.goToStep('ChangeBillTo')}
                className="ml-4 flex-shrink-0 text-xs underline"
              >
                Change
              </a>
            </li>
          </ul>
        </dd>
        {/* display the payment method - END */}

        <div className="mt-3 text-red-700">
          {errors?.selectedShippingMethod?.id &&
            'Please select a shipping method to continue'}
        </div>

        <Button
          text={`Pay ${
            config.currencySymbol
          }${checkout.totals.grandTotal.toFixed(2)}`}
        />
        <div className="mt-3 text-xs text-center">
          By clicking Pay, you are agreeing to the{' '}
          <a
            href={`${checkout.storeUrl}terms.asp`}
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium text-vol-primary hover:text-indigo-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
          >
            terms and conditions
          </a>
          .
        </div>

        <Footer />
      </form>
    </div>
  );
});

export default OneClick;
