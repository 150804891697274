import React from 'react';
import { observer } from 'mobx-react-lite';
import Interweave from 'interweave';
import { useMst } from '../models/Root';

const Footer: React.FC = observer(() => {
  const { checkout, config } = useMst();

  // fetch user isLoggedIn status a ton of times, on each render, just for debugging. Remove this later...
  checkout.fetchUser();

  return (
    <div>
      <div className="mt-3 sm:mt-6 text-center">
        <div className="text-center">
          <svg
            className="h-4 w-4 text-gray-500 inline-block mx-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M11,14.7324356 C11.5978014,14.3866262 12,13.7402824 12,13 C12,11.8954305 11.1045695,11 10,11 C8.8954305,11 8,11.8954305 8,13 C8,13.7402824 8.40219863,14.3866262 9,14.7324356 L9,17 L11,17 L11,14.7324356 Z M13,6 C13,4.34314575 11.6568542,3 10,3 C8.34314575,3 7,4.34314575 7,6 L7,8 L13,8 L13,6 Z M4,8 L4,6 C4,2.6862915 6.6862915,0 10,0 C13.3137085,0 16,2.6862915 16,6 L16,8 L17.0049107,8 C18.1067681,8 19,8.90195036 19,10.0085302 L19,17.9914698 C19,19.1007504 18.1073772,20 17.0049107,20 L2.99508929,20 C1.8932319,20 1,19.0980496 1,17.9914698 L1,10.0085302 C1,8.8992496 1.8926228,8 2.99508929,8 L4,8 Z"
              id="lock-closed"
            />
          </svg>
          <span className="text-xs">Safe & secure checkout</span>
          {config.features.enableVolusionLogo && (
            <>
              <span className="text-xs">by</span>
              <img
                item-prop="logo"
                src="https://www.volusion.com/assets/images/color-logo.svg"
                className="inline-block ml-2"
                width="90"
                alt="Volusion Logo"
              />
            </>
          )}
        </div>
      </div>
      <div className="mt-6 text-center">
        <span className="text-center">
          <a
            className="text-xs"
            href={`${checkout.storeUrl}shoppingcart.asp`}
            target="_top"
          >
            <svg
              className="h-4 w-4 text-gray-500 inline-block mx-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <polygon
                id="arrow"
                points="3.82842712 9 9.89949494 2.92893219 8.48528137 1.51471863 0 10 0.707106781 10.7071068 8.48528137 18.4852814 9.89949494 17.0710678 3.82842712 11 20 11 20 9 3.82842712 9"
              />
            </svg>
            Return to cart
          </a>
        </span>
      </div>
      {checkout.customer.id > 0 && (
        <div className="mt-6 text-center">
          <span className="text-center text-xs">
            Logged in as Customer ID {checkout.customer.id}.{' '}
            <a
              href="#ShipTo"
              onClick={() => checkout.startNewCheckout()}
              className="text-xs underline"
            >
              Logout
            </a>
            .
          </span>
        </div>
      )}
      {config.messages.footer && (
        <div className="mt-6 text-xs text-center">
          <Interweave content={config.messages.footer} />
        </div>
      )}
    </div>
  );
});

export default Footer;
