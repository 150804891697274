/* eslint no-param-reassign: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */
/* eslint prefer-template: "off" */
/* eslint no-else-return: "off" */
/* eslint @typescript-eslint/camelcase: "off" */
/* eslint import/no-cycle: "off" */
/* eslint prefer-destructuring: "off" */
/* eslint no-return-assign: "off" */
/* eslint consistent-return: "off" */

import {
  types,
  flow,
  getSnapshot,
  applySnapshot,
  getRoot,
  Instance,
} from 'mobx-state-tree';
import qs from 'qs';
import * as Sentry from '@sentry/react';

import { IRootStore } from './Root';

export const ServerErrors = types.model({
  field: types.string,
  messages: types.array(types.string),
});

export const Option = types.model({
  id: types.string,
  optionText: types.string,
});

export const Kit = types.model({
  code: types.string,
  name: types.string,
  quantity: types.number,
  cost: types.number,
  optionId: types.string,
  isSetupCost: types.string,
  imgUrl: types.string,
  productUrl: types.string,
});

export const Item = types.model({
  qty: types.number,
  code: types.string,
  giftCardSendMethod: types.null,
  name: types.string,
  avalaraTaxCode: types.optional(types.null, null),
  giftCardToName: types.null,
  giftCardFromName: types.null,
  options: types.array(Option), // optional
  taxable: types.boolean,
  tax: types.model({
    tax3: types.number,
    tax2: types.number,
    tax1: types.number,
  }),
  id: types.number,
  giftWrap: types.model({
    price: types.number,
    message: types.string,
    selected: types.boolean,
  }),
  kits: types.array(Kit), // optional
  giftCardEmailAddress: types.null,
  isGiftWrapAvailable: types.boolean,
  imgUrl: types.string,
  productUrl: types.string,
  pricing: types.model({
    subtotal: types.number,
    recurringPrice: types.model({
      price: types.number,
      startPrice: types.number,
      thenXMonths: types.string,
      everyXMonths: types.string,
      startXMonths: types.null,
    }),
    unitPrice: types.number,
  }),
  giftCardMessage: types.null,
});

export const ShippingMethod = types.model({
  id: types.number,
  selected: types.boolean,
  sortOrder: types.string,
  price: types.number,
  name: types.string,
});

export const Customer = types.model({
  id: types.number,
  emailAddress: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  pageName: types.maybeNull(types.string),
  storeCredit: types.number,
  emailOptIn: types.maybeNull(types.boolean),
  isRegistered: types.maybeNull(types.boolean),
  justRegistered: types.maybeNull(types.boolean),
  loginEmail: types.maybeNull(types.string),
  isLoggedIn: types.maybeNull(types.boolean),
});

export const SelectedShippingMethod = types.model({
  id: types.number,
});

export const Misc = types.model({
  comment: types.maybeNull(types.string),
  isGift: types.maybeNull(types.boolean),
});

export const CustomField = types.model({
  foo: types.string, // todo
});

export const Discount = types.model({
  id: types.string,
  discountType: types.number,
  name: types.string,
  value: types.number,
  couponCode: types.string,
});

export const Totals = types.model({
  shipping: types.number,
  tax1: types.number,
  tax2: types.number,
  tax3: types.number,
  taxTotal: types.number,
  kitItems: types.number,
  giftCardAmountUsed: types.number,
  discounts: types.number,
  giftWrap: types.number,
  qty: types.number,
  items: types.number,
  grandTotal: types.number,
});

export const PaypalInfo = types.model({
  paymentStatus: types.null,
  totalAmount: types.number,
  transactionId: types.null,
  paymentMethodId: -types.number,
  payerId: types.null,
  token: types.null,
  useStoreCredit: types.boolean,
});

export const ShippingAddress = types.model({
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  companyName: types.maybeNull(types.string),
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  residential: types.maybeNull(types.boolean),
  preferred: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
});

export const BillingAddress = types.model({
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  companyName: types.maybeNull(types.string),
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  preferred: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
});

export const PaymentProfile = types
  .model({
    id: types.maybeNull(types.number),
    method: types.maybeNull(types.string),
    card: types.model({
      id: types.maybeNull(types.number),
      cardNumber: types.maybeNull(types.string),
      pCIaaSId: types.maybeNull(types.string),
      holdersName: types.maybeNull(types.string),
      expMonth: types.maybeNull(types.string),
      expYear: types.maybeNull(types.string),
      last4: types.maybeNull(types.string),
      issueMonth: types.maybeNull(types.string),
      issueNumber: types.maybeNull(types.string),
      cardType: types.maybeNull(types.string),
      cardTypeName: types.maybeNull(types.string),
      issueYear: types.maybeNull(types.string),
      cVV: types.maybeNull(types.string),
    }),
    preferred: types.maybeNull(types.boolean),
  })
  .actions((self) => {
    const setPaymentProfileWithToken = function (json: Record<string, any>) {
      applySnapshot(self, json);
    };

    return {
      setPaymentProfileWithToken,
    };
  });

export const User = types.model({
  id: types.number,
  shippingAddresses: types.array(ShippingAddress),
  billingAddresses: types.array(BillingAddress),
  paymentProfiles: types.array(PaymentProfile),
});

export type ICheckout = Instance<typeof Checkout>;
export const Checkout: any = types
  .model({
    customer: Customer,
    shippingAddress: ShippingAddress,
    billingAddress: BillingAddress,
    paymentProfile: PaymentProfile,
    paypalInfo: PaypalInfo,
    totals: Totals,
    misc: Misc,
    shippingMethods: types.array(ShippingMethod),
    selectedShippingMethod: types.maybe(SelectedShippingMethod),
    customFields: types.array(CustomField),
    discounts: types.array(Discount),
    items: types.array(Item),

    step: types.optional(types.string, 'ShipTo'),
    state: types.optional(types.string, ''),
    errors: types.array(ServerErrors),
    loginError: types.maybeNull(types.string),
    addressError: types.maybeNull(types.string),
    cartIsExpanded: types.optional(types.boolean, false),
    billToSame: types.optional(types.boolean, true),
    cartId: types.optional(types.maybeNull(types.string), null),
    storeUrl: types.optional(types.maybeNull(types.string), null),
    orderId: types.optional(types.maybeNull(types.number), null),
    user: User,
  })
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => {
    const init = flow(function* () {
      console.log('fetchUser go do it');
      yield fetchUser();

      if (self.customer.id > 0) {
        self.customer.isLoggedIn = true;
        // hydrate one click checkout
        yield fetchCart();
        yield fetchUserShippingAddresses();
        yield getRates();
        yield fetchUserPaymentProfiles();
        yield fetchUserBillingAddresses();
        // if we have saved information for ship to and credit card payment, then let's go straight to one click checkout
        if (
          self.shippingAddress.id &&
          self.paymentProfile.id &&
          self.paymentProfile.card.pCIaaSId
        ) {
          self.step = 'OneClick';
        } else {
          // they don't have everything needed for one click checkout, so clear their email as well from first screen
          // self.customer.id = 0;
          // self.customer.emailAddress = "";
          // TODO: We should probably log them out if they are attempting to
          // place an order under a different email, so that they don't get
          // special pricing or run into errors on order submit trying to use
          // special pricing
          // I mean, if they're logged in, we should force their email to be
          // whatever they're logged in as, or if they want to chagne their email
          // let them explicitly logout to do that
        }
      } else {
        // start normal anonymous checkout
        yield fetchCart();
      }

      console.log('fetchCart is done doing it');
      self.state = 'done';
    });

    const checkIfEmailHasRegisteredAccount = flow(function* (email: any) {
      // first... if they're already logged in, and they try to change their email address, then log them out
      // if (self.customer.isLoggedIn) {
      //   yield logoutSomehow(getSnapshot(self));
      //   self.customer.isLoggedIn = false;
      // }

      // check if this email is registered
      const response = yield window
        .fetch(
          `${self.storeUrl}api/v1/users?email=${encodeURIComponent(email)}`,
          {
            credentials: 'include',
          }
        )
        .catch(function (error) {
          console.log('Error ', error);
        });
      if (response.status === 200) {
        console.log(`email ${email} is registered, prompt optional login`);
        self.customer.emailAddress = email;
        self.customer.isRegistered = true;

        // if we set this state to login, we can prompt them to login.
        // But it's not a great experience for most users who don't remember their passwords
        self.state = 'login';
      } else {
        self.customer.isRegistered = false;
      }
    });

    const fetchUser = flow(function* () {
      const response = yield window.fetch(
        `${self.storeUrl}api/v1/users/current`,
        {
          credentials: 'include',
        }
      );
      if (response.status === 200) {
        const responseJsonEnvelope = yield response.json();
        const responseJson = responseJsonEnvelope.data;
        console.log(responseJson);
        if (responseJson.id > 0) {
          console.log('user is logged in!!!!');
          self.customer.id = responseJson.id;
          self.customer.emailAddress = responseJson.email;
          Sentry.configureScope(function (scope) {
            scope.setUser({ email: responseJson.email });
          });
          if (!responseJson.isAnonymous) {
            self.customer.isRegistered = true;
          }
        }
      } else {
        console.log('user is not logged in');
      }
      console.log('fetchUser finished');
    });

    const fetchUserShippingAddresses = flow(function* () {
      const response = yield window.fetch(
        `${self.storeUrl}api/v1/users/current/shippingAddresses`,
        {
          credentials: 'include',
        }
      );
      if (response.status === 200) {
        const responseJsonEnvelope = yield response.json();
        const responseJson = responseJsonEnvelope.data;
        console.log('api/v1/users/current/shippingAddresses:', responseJson);

        if (responseJson.length > 0) {
          applySnapshot(self.user.shippingAddresses, responseJson);

          self.shippingAddress.id = responseJson[0].id;
          self.shippingAddress.firstName = responseJson[0].firstName;
          self.shippingAddress.lastName = responseJson[0].lastName;
          self.shippingAddress.address1 = responseJson[0].address1;
          self.shippingAddress.address2 = responseJson[0].address2;
          self.shippingAddress.city = responseJson[0].city;
          self.shippingAddress.state = responseJson[0].state;
          self.shippingAddress.postalCode = responseJson[0].postalCode;
          self.shippingAddress.country = responseJson[0].country;
          self.shippingAddress.phoneNumber = responseJson[0].phoneNumber;

          // for now set billing address to the same
          self.billingAddress.id = responseJson[0].id;
          self.billingAddress.firstName = responseJson[0].firstName;
          self.billingAddress.lastName = responseJson[0].lastName;
          self.billingAddress.address1 = responseJson[0].address1;
          self.billingAddress.address2 = responseJson[0].address2;
          self.billingAddress.city = responseJson[0].city;
          self.billingAddress.state = responseJson[0].state;
          self.billingAddress.postalCode = responseJson[0].postalCode;
          self.billingAddress.country = responseJson[0].country;
          self.billingAddress.phoneNumber = responseJson[0].phoneNumber;
        }
      } else {
        console.log('user does not have any shipping addresses');
      }
    });

    const fetchUserBillingAddresses = flow(function* () {
      const response = yield window.fetch(
        `${self.storeUrl}api/v1/users/current/billingAddresses`,
        {
          credentials: 'include',
        }
      );
      if (response.status === 200) {
        const responseJsonEnvelope = yield response.json();
        const responseJson = responseJsonEnvelope.data;
        console.log('api/v1/users/current/billingAddresses:', responseJson);

        if (responseJson.length > 0) {
          applySnapshot(self.user.billingAddresses, responseJson);
          self.billingAddress.id = responseJson[0].id;
          self.billingAddress.firstName = responseJson[0].firstName;
          self.billingAddress.lastName = responseJson[0].lastName;
          self.billingAddress.address1 = responseJson[0].address1;
          self.billingAddress.address2 = responseJson[0].address2;
          self.billingAddress.city = responseJson[0].city;
          self.billingAddress.state = responseJson[0].state;
          self.billingAddress.postalCode = responseJson[0].postalCode;
          self.billingAddress.country = responseJson[0].country;
          self.billingAddress.phoneNumber = responseJson[0].phoneNumber;
        }
      } else {
        console.log('user does not have any billing addresses');
      }
    });

    const fetchUserPaymentProfiles = flow(function* () {
      const response = yield window.fetch(
        `${self.storeUrl}api/v1/users/current/paymentProfiles`,
        {
          credentials: 'include',
        }
      );
      if (response.status === 200) {
        const responseJsonEnvelope = yield response.json();
        const responseJson = responseJsonEnvelope.data;
        console.log(responseJson);
        applySnapshot(self.user.paymentProfiles, responseJson);
        if (responseJson.length > 0) {
          // update checkout model
          self.paymentProfile.setPaymentProfileWithToken(responseJson[0]);
        }
      } else {
        console.log('user does not have any shipping addresses');
      }
    });

    const fetchCart = flow(function* () {
      console.log('cartBefore', getSnapshot(self));
      console.log('cartId using:', self.cartId);
      const response = yield window.fetch(
        `${self.storeUrl}api/v1/carts/${self.cartId}`,
        {
          credentials: 'include',
        }
      );
      const responseJsonEnvelope = yield response.json();
      const responseJson = responseJsonEnvelope.data;
      console.log(responseJson);
      if (responseJson.shippingAddress.country === null) {
        responseJson.shippingAddress.country = 'United States';
      } // set default

      // 🔒 retain some data
      responseJson.customer.id = self.customer.id;
      responseJson.customer.isLoggedIn = self.customer.isLoggedIn;
      responseJson.customer.emailAddress = self.customer.emailAddress;
      responseJson.customer.emailOptIn = self.customer.emailOptIn;
      responseJson.paymentProfile.method = self.paymentProfile.method;
      responseJson.cartId = self.cartId;
      responseJson.storeUrl = self.storeUrl;
      responseJson.user = self.user;
      responseJson.state = self.state;
      responseJson.step = self.step;

      console.log('items.length:', responseJson.items.length);
      if (responseJson.items.length === 0) {
        self.step = 'Empty'; // if cart is empty, render empty view
        console.log('self.step:', self.step);
      } else {
        applySnapshot(self, responseJson);
      }

      console.log('fetchCart finished');
      console.log('cartAfter', getSnapshot(self));
    });

    const expandCart = function () {
      self.cartIsExpanded = true;
    };

    const collapseCart = function () {
      self.cartIsExpanded = false;
    };

    const goToStep = function (step: string) {
      self.step = step;
    };
    const setState = function (state: string) {
      self.state = state;
    };
    const setErrors = function (errors: any) {
      if (errors.length > 0) {
        applySnapshot(self.errors, errors);
        self.state = 'error';
      } else {
        self.state = 'done';
      }
    };

    const setPaymentMethod = function (methodType: string) {
      const method = self.root.config.payments.enabledPaymentMethods.find(
        (o) => o.methodType === methodType
      );
      if (method) {
        self.paymentProfile.card.cardType = method.id.toString();
        self.paymentProfile.card.cardTypeName = method.methodType;
        self.paymentProfile.method = method.methodType;
      }
      if (method?.methodType !== 'Credit Card') {
        self.paymentProfile.card.cardNumber = null;
        self.paymentProfile.card.expMonth = null;
        self.paymentProfile.card.expYear = null;
        self.paymentProfile.card.holdersName = null;
        self.paymentProfile.card.last4 = null;
        self.paymentProfile.card.pCIaaSId = null;
      }
    };

    const updateBillToSame = function (bool: boolean) {
      self.billToSame = bool;

      if (!self.billToSame) {
        // clear billing address
        self.billingAddress.firstName = null;
        self.billingAddress.lastName = null;
        self.billingAddress.address1 = null;
        self.billingAddress.address2 = null;
        self.billingAddress.city = null;
        self.billingAddress.state = null;
        self.billingAddress.postalCode = null;
      }
    };

    const updateShipTo = flow(function* (json: any) {
      Sentry.configureScope(function (scope) {
        scope.setUser({ email: json.checkout.customer.emailAddress });
      });

      self.customer.emailAddress = json.checkout.customer.emailAddress;
      self.customer.emailOptIn = json.checkout.customer.emailOptIn;

      self.shippingAddress.firstName = json.checkout.shippingAddress.firstName;
      self.shippingAddress.lastName = json.checkout.shippingAddress.lastName;
      self.shippingAddress.address1 = json.checkout.shippingAddress.address1;
      self.shippingAddress.address2 = json.checkout.shippingAddress.address2;
      self.shippingAddress.city = json.checkout.shippingAddress.city;
      self.shippingAddress.state = json.checkout.shippingAddress.state;
      self.shippingAddress.postalCode =
        json.checkout.shippingAddress.postalCode;
      self.shippingAddress.country = json.checkout.shippingAddress.country;
      self.shippingAddress.phoneNumber =
        json.checkout.shippingAddress.phoneNumber;

      if (self.step === 'ShipTo') {
        // only set billing address to same if we're on first step of checkout
        self.billingAddress.firstName = json.checkout.shippingAddress.firstName;
        self.billingAddress.lastName = json.checkout.shippingAddress.lastName;
        self.billingAddress.address1 = json.checkout.shippingAddress.address1;
        self.billingAddress.address2 = json.checkout.shippingAddress.address2;
        self.billingAddress.city = json.checkout.shippingAddress.city;
        self.billingAddress.state = json.checkout.shippingAddress.state;
        self.billingAddress.postalCode =
          json.checkout.shippingAddress.postalCode;
        self.billingAddress.country = json.checkout.shippingAddress.country;
      }

      // if US address, check if it's deliverable
      // TODO: we should have a config to enable address verfifications at all
      const enableAddressVerification = false;
      if (
        enableAddressVerification &&
        self.shippingAddress.country === 'United States'
      ) {
        const responseJson = yield isAddressDeliverable(
          json.checkout.shippingAddress
        );
        // todo: add a config variable for weShipToPOBoxes to enable this block
        // for example this is a rule https://www.jonhartdesign.com/ has
        // if (responseJson.components.zip_code_type === "po_box") {
        //   self.state = "notDeliverable";
        //   self.addressError = "Sorry, we do not deliver to PO Boxes";
        //   console.log("NO! we don't deliver to PO Boxes 🚧");
        // } else
        if (responseJson.deliverability !== 'deliverable') {
          self.state = 'notDeliverable';
          self.addressError =
            'Sorry, our address validation service says that the address you entered is invalid and not deliverable by the USPS.';
          console.log('NO! address is not deliverable 🚧');
        } else {
          console.log('YES! address is deliverable ✅');
          getRates();

          // navigate to next screen
          if (self.step === 'ChangeShipTo') {
            self.step = 'OneClick';
          } else {
            self.step = 'Delivery';
          }
        }
      } else {
        getRates();

        // navigate to next screen
        if (self.step === 'ChangeShipTo') {
          self.step = 'OneClick';
        } else {
          self.step = 'Delivery';
        }
      }
    });

    const addShipTo = flow(function* (json: any) {
      self.shippingAddress.firstName = json.checkout.shippingAddress.firstName;
      self.shippingAddress.lastName = json.checkout.shippingAddress.lastName;
      self.shippingAddress.address1 = json.checkout.shippingAddress.address1;
      self.shippingAddress.address2 = json.checkout.shippingAddress.address2;
      self.shippingAddress.city = json.checkout.shippingAddress.city;
      self.shippingAddress.state = json.checkout.shippingAddress.state;
      self.shippingAddress.postalCode =
        json.checkout.shippingAddress.postalCode;
      self.shippingAddress.country = json.checkout.shippingAddress.country;

      // if US address, check if it's deliverable
      // TODO: we should have a config to enable address verfifications at all
      const enableAddressVerification = false;
      if (
        enableAddressVerification &&
        self.shippingAddress.country === 'United States'
      ) {
        const responseJson = yield isAddressDeliverable(
          json.checkout.shippingAddress
        );
        // todo: add a config variable for weShipToPOBoxes to enable this block
        // for example this is a rule https://www.jonhartdesign.com/ has
        // if (responseJson.components.zip_code_type === "po_box") {
        //   self.state = "notDeliverable";
        //   self.addressError = "Sorry, we do not deliver to PO Boxes";
        //   console.log("NO! we don't deliver to PO Boxes 🚧");
        // } else
        if (responseJson.deliverability !== 'deliverable') {
          self.state = 'notDeliverable';
          self.addressError =
            'Sorry, our address validation service says that the address you entered is invalid and not deliverable by the USPS.';
          console.log('NO! address is not deliverable 🚧');
        } else {
          console.log('YES! address is deliverable ✅');
          self.user.shippingAddresses.push({
            firstName: self.shippingAddress.firstName,
            lastName: self.shippingAddress.lastName,
            address1: self.shippingAddress.address1,
            address2: self.shippingAddress.address2,
            city: self.shippingAddress.city,
            state: self.shippingAddress.state,
            postalCode: self.shippingAddress.postalCode,
            country: self.shippingAddress.country,
          });
          yield addSavedObjectToUserSomehow(
            'shippingaddresses',
            `${self.storeUrl}`,
            getSnapshot(self.shippingAddress)
          );

          getRates();

          // navigate to next screen
          self.step = 'OneClick';
        }
      } else {
        self.user.shippingAddresses.push({
          firstName: self.shippingAddress.firstName,
          lastName: self.shippingAddress.lastName,
          address1: self.shippingAddress.address1,
          address2: self.shippingAddress.address2,
          city: self.shippingAddress.city,
          state: self.shippingAddress.state,
          postalCode: self.shippingAddress.postalCode,
          country: self.shippingAddress.country,
        });
        yield addSavedObjectToUserSomehow(
          'shippingaddresses',
          `${self.storeUrl}`,
          getSnapshot(self.shippingAddress)
        );
        getRates();

        // navigate to next screen
        self.step = 'OneClick';
      }
    });

    const addBillTo = flow(function* (json: any) {
      self.billingAddress.firstName = json.checkout.billingAddress.firstName;
      self.billingAddress.lastName = json.checkout.billingAddress.lastName;
      self.billingAddress.address1 = json.checkout.billingAddress.address1;
      self.billingAddress.address2 = json.checkout.billingAddress.address2;
      self.billingAddress.city = json.checkout.billingAddress.city;
      self.billingAddress.state = json.checkout.billingAddress.state;
      self.billingAddress.postalCode = json.checkout.billingAddress.postalCode;
      self.billingAddress.country = json.checkout.billingAddress.country;

      self.user.billingAddresses.push({
        firstName: self.billingAddress.firstName,
        lastName: self.billingAddress.lastName,
        address1: self.billingAddress.address1,
        address2: self.billingAddress.address2,
        city: self.billingAddress.city,
        state: self.billingAddress.state,
        postalCode: self.billingAddress.postalCode,
        country: self.billingAddress.country,
      });
      yield addSavedObjectToUserSomehow(
        'billingaddresses',
        `${self.storeUrl}`,
        getSnapshot(self.billingAddress)
      );

      // navigate to next screen
      self.step = 'OneClick';
    });

    const updateBillTo = function (json: any) {
      self.billingAddress.firstName = json.checkout.billingAddress.firstName;
      self.billingAddress.lastName = json.checkout.billingAddress.lastName;
      self.billingAddress.address1 = json.checkout.billingAddress.address1;
      self.billingAddress.address2 = json.checkout.billingAddress.address2;
      self.billingAddress.city = json.checkout.billingAddress.city;
      self.billingAddress.state = json.checkout.billingAddress.state;
      self.billingAddress.postalCode = json.checkout.billingAddress.postalCode;
      self.billingAddress.country = json.checkout.billingAddress.country;

      // navigate to next screen
      self.step = 'OneClick';
    };

    const addPay = flow(function* (json: any) {
      self.errors.splice(0); // clear errors
      if (self.paymentProfile.method === 'Credit Card') {
        yield self.root.tokenization.updateTokenization(json);
      }
      self.user.paymentProfiles.push(getSnapshot(self.paymentProfile));
      yield addSavedObjectToUserSomehow(
        'paymentprofiles',
        `${self.storeUrl}`,
        getSnapshot(self.paymentProfile)
      );

      // navigate to next screen
      self.step = 'OneClick';
    });

    const login = flow(function* (json: any) {
      // FYI: this login api call is the only one that doesn't call the /api/v1/. So it's a little different.
      console.log('login json:', json);
      self.loginError = ''; // clear errors (especially so that user can see we're submitting the value again)
      try {
        json.storeUrl = self.storeUrl; // we usually pass in getSnapshot(self), but since we're just passing in json, make sure to pass in the storeUrl
        console.log('json.email', json.email);
        if (!json.email) {
          json.email = self.customer.emailAddress;
        }
        const response = yield loginSomehow(json);
        const responseJsonEnvelope = yield response.json();
        console.log('login responseJsonEnvelope', responseJsonEnvelope);
        if (responseJsonEnvelope.Errors.length > 0) {
          console.log(
            "here's the user create errors:",
            responseJsonEnvelope.Errors
          );
          self.loginError = responseJsonEnvelope.Errors[0];
        } else {
          console.log('login response data', responseJsonEnvelope.data);
          self.loginError = null;
          self.state = 'pending';
          init(); // call init to take them to one click checkout
        }
      } catch (error) {
        console.error('Failed to login', error);
      }
    });

    const isAddressDeliverable = flow(function* (json: any) {
      try {
        const response = yield fetch(
          'https://api.lob.com/v1/us_verifications',
          {
            method: 'POST',
            headers: {
              Authorization: `Basic ${btoa(
                'test_pub_f4f4a08b17087687081eb669a39cebd:'
              )}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              recipient: `${json.firstName} ${json.lastName}`,
              primary_line: json.address1,
              secondary_line: json.address2,
              city: json.city,
              state: json.state,
              zip_code: json.postalCode,
            }),
          }
        );
        if (response.ok) {
          const responseJson = yield response.json();
          console.log('lob json response:', responseJson);
          return responseJson;
        }
        console.log('lob response was not ok:', response.text());
        return response;
      } catch (error) {
        console.log('lob had an error:', error);
        return error;
      }
    });

    const getRates = flow(function* () {
      self.state = 'pending';
      try {
        const response = yield getRatesSomehow(getSnapshot(self));
        const responseJsonEnvelope = yield response.json();
        const responseJson = responseJsonEnvelope.data;
        console.log('rates response', responseJson);
        // only use the: shipping methods, totals, items

        // replace &reg; with the registration symbol in the shippingMethodName
        responseJson.shippingMethods.forEach(
          (o: object, i: number, method: any) =>
            (method[i].name = method[i].name.replace(
              '&reg;',
              ` ${String.fromCharCode(0x00ae)}`
            ))
        );

        applySnapshot(self.shippingMethods, responseJson.shippingMethods);
        applySnapshot(self.totals, responseJson.totals);
        applySnapshot(self.items, responseJson.items);
        self.state = 'done';
      } catch (error) {
        console.error('Failed to fetch rates', error);
        self.state = 'error';
      }
    });

    const updateDelivery = flow(function* (json: any) {
      self.selectedShippingMethod = {
        id: +json.selectedShippingMethod?.id,
      };

      // loop through self.shippingMethods and mark selected: true or false
      // and figure out how much it costs
      let cost = 0;
      // eslint-disable-next-line
      self.shippingMethods.map(function (method) {
        if (method.id === +json.selectedShippingMethod.id) {
          method.selected = true;
          cost = +method.price;
        } else {
          method.selected = false;
        }
      });

      // update totals immediately, while we wait for server response (not necessary, can probably remove this)
      self.totals.shipping = cost;
      console.log('self.totals.items', self.totals.items);
      console.log('self.totals.discounts', self.totals.discounts);
      console.log('self.totals.shipping', self.totals.shipping);
      console.log('self.totals.taxTotal', self.totals.taxTotal);
      console.log(
        'self.totals.giftCardAmountUsed',
        self.totals.giftCardAmountUsed
      );
      self.totals.grandTotal =
        Math.round(
          (self.totals.items +
            self.totals.discounts +
            self.totals.shipping -
            self.totals.giftCardAmountUsed +
            self.totals.taxTotal +
            Number.EPSILON) *
            100
        ) / 100;
      console.log('self.totals.grandTotal', self.totals.grandTotal);

      // now post this to the server, in case they charge tax on shipping, to get the perfect taxTotal, and therefore grandTotal:
      self.state = 'pending';
      try {
        const response = yield getRatesSomehow(getSnapshot(self));
        const responseJsonEnvelope = yield response.json();
        const responseJson = responseJsonEnvelope.data;
        console.log('rates response', responseJson);
        // only use the: shipping methods, totals, items

        // replace &reg; with the registration symbol in the shippingMethodName
        responseJson.shippingMethods.forEach(
          (o: object, i: number, method: any) =>
            (method[i].name = method[i].name.replace(
              '&reg;',
              ` ${String.fromCharCode(0x00ae)}`
            ))
        );

        applySnapshot(self.shippingMethods, responseJson.shippingMethods);
        applySnapshot(self.totals, responseJson.totals);
        applySnapshot(self.items, responseJson.items);
        self.state = 'done';
      } catch (error) {
        console.error('Failed to fetch rates', error);
        self.state = 'error';
      }

      if (self.step !== 'OneClick') {
        self.step = 'Pay';
      }
    });

    const submitOrder = flow(function* (json: any) {
      self.state = 'pending'; // update the UI to display loading indicator
      self.errors.splice(0); // clear errors

      // -------------------------------------------------
      // 1. Set billing address
      console.log('json', json);
      if (self.billToSame) {
        // set billing address to match shipping address
        applySnapshot(self.billingAddress, getSnapshot(self.shippingAddress));
      } else {
        // use shopper entered billing address
        applySnapshot(self.billingAddress, json.checkout.billingAddress);
      }

      // -------------------------------------------------
      // 2. Call PCIaaS to get a token
      if (self.paymentProfile.method === 'Credit Card') {
        yield self.root.tokenization.updateTokenization(json);
      }

      // -------------------------------------------------
      // 3. Create Customer if needed
      if (self.customer.id > 0) {
        // we already have a customer.id which means the user is currently logged in
      } else {
        // not customer.id yet, so create new anonymous customer
        const customerResponseJsonEnvelope = yield createCustomerSomehow(
          getSnapshot(self)
        );
        if (customerResponseJsonEnvelope.errors.length > 0) {
          console.log(
            "here's the user create errors:",
            customerResponseJsonEnvelope.errors
          );
          applySnapshot(self.errors, customerResponseJsonEnvelope.errors);
          self.state = 'error';
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        } else {
          self.customer.id = customerResponseJsonEnvelope.data.id;
          console.log('set the self.customer.id', self.customer.id);
        }
      }

      // -------------------------------------------------
      // 4. Submit the order
      if (self.customer.id > 0) {
        const orderResponseJsonEnvelope = yield submitOrderSomehow(
          getSnapshot(self)
        );
        if (orderResponseJsonEnvelope.errors.length > 0) {
          console.log(
            "here's the order create errors:",
            orderResponseJsonEnvelope.errors
          );
          applySnapshot(self.errors, orderResponseJsonEnvelope.errors);
          self.state = 'error';
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        } else {
          self.orderId = orderResponseJsonEnvelope.data.id;
          console.log('set the self.orderId', self.orderId);

          // -------------------------------------------------
          // 5. Save data to customer record for faster checkout next time
          if (self.errors.length <= 0) {
            yield addSavedObjectToUserSomehow(
              'shippingaddresses',
              `${self.storeUrl}`,
              getSnapshot(self.shippingAddress)
            );
            yield addSavedObjectToUserSomehow(
              'billingaddresses',
              `${self.storeUrl}`,
              getSnapshot(self.billingAddress)
            );
            yield addSavedObjectToUserSomehow(
              'paymentprofiles',
              `${self.storeUrl}`,
              getSnapshot(self.paymentProfile)
            );
          }

          self.state = 'done';
          self.step = 'Thanks';

          // window.top.location.replace(
          //   self.storeUrl +
          //     "OrderFinished.asp?Order=Finished&sendemail=true&OrderID=" +
          //     self.orderId
          // );
        }
      }
    });

    const submitOneClick = flow(function* (json: any) {
      self.state = 'pending'; // update the UI to display loading indicator

      // -------------------------------------------------
      // 1. Save delivery method
      updateDelivery(json);

      // -------------------------------------------------
      // 2. Submit the order
      const orderResponseJsonEnvelope = yield submitOrderSomehow(
        getSnapshot(self)
      );
      if (orderResponseJsonEnvelope.errors.length > 0) {
        console.log(
          "here's the order create errors:",
          orderResponseJsonEnvelope.errors
        );
        applySnapshot(self.errors, orderResponseJsonEnvelope.errors);
        self.state = 'error';
      } else {
        self.orderId = orderResponseJsonEnvelope.data.id;
        console.log('set the self.orderId', self.orderId);
        self.state = 'done';
        self.step = 'Thanks';

        // window.top.location.replace(
        //   self.storeUrl +
        //     "OrderFinished.asp?Order=Finished&sendemail=true&OrderID=" +
        //     self.orderId
        // );
      }
    });

    const addPassword = flow(function* (json: any) {
      console.log('new password:', json);
      self.customer.password = json.password;
      const responseJsonEnvelope = yield addPasswordSomehow(getSnapshot(self));
      if (responseJsonEnvelope.errors.length > 0) {
        console.log(
          "here's the add password errors:",
          responseJsonEnvelope.errors
        );
        applySnapshot(self.errors, responseJsonEnvelope.errors);
        self.state = 'error';
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else {
        self.customer.isRegistered = true;
        self.customer.justRegistered = true;
      }
    });

    const startNewCheckout = flow(function* () {
      // log them out
      yield logoutSomehow(getSnapshot(self));
      window.location.reload();
    });

    const startOver = function (json: any) {
      self.step = 'ShipTo';
      window.top.location.replace(json.storeUrl);
    };

    return {
      fetchCart,
      fetchUser,
      checkIfEmailHasRegisteredAccount,
      expandCart,
      collapseCart,
      goToStep,
      setState,
      setErrors,
      afterCreate() {
        init();
      },
      updateShipTo,
      addShipTo,
      updateBillTo,
      addBillTo,
      addPay,
      setPaymentMethod,
      updateBillToSame,
      login,
      getRates,
      updateDelivery,
      submitOrder,
      submitOneClick,
      addPassword,
      startNewCheckout,
      startOver,
    };
  });

async function getRatesSomehow(json: any) {
  console.log('getRatesSomehow:', json);
  const url = `${json.storeUrl}api/v1/carts/${json.cartId}`;

  try {
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        vMethod: 'PUT',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    });
    if (response.ok) {
      return response;
    }
    return response;
    // todo
  } catch (error) {
    // todo
    return error;
  }
}

async function logoutSomehow(json: any) {
  console.log('logoutSomehow:', json);
  const url = `${json.storeUrl}login.asp?logout=yes`;

  try {
    const response = await fetch(url, {
      credentials: 'include',
      method: 'GET',
    });
    if (response.ok) {
      return response;
    }
    return response;
    // todo
  } catch (error) {
    // todo
    return error;
  }
}

async function loginSomehow(json: any) {
  console.log('loginSomehow:', json);
  const url = `${json.storeUrl}ajax_receiver.asp?system=login`;

  try {
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify({
        email: json.email,
        password: json.password,
        CustomerNewOld: 'old',
      }),
    });
    if (response.ok) {
      return response;
    }
    return response;
    // todo
  } catch (error) {
    // todo
    return error;
  }
}

async function addPasswordSomehow(json: any) {
  console.log('addPasswordSomehow:', json);
  const url = `${json.storeUrl}api/v1/users`;

  try {
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        vMethod: 'PUT',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: json.customer.emailAddress,
        password: json.customer.password,
        anonymous: false,
        firstName: json.shippingAddress.firstName,
        lastName: json.shippingAddress.lastName,
        emailOptIn: json.customer.emailOptIn,
        storeCreditAvailable: 0,
      }),
    });
    const responseJsonEnvelope = await response.json();
    return responseJsonEnvelope;
  } catch (error) {
    // todo
    console.log('addPasswordSomehow catch error todo');
  }
}

async function createCustomerSomehow(json: any) {
  const url = `${json.storeUrl}api/v1/users`;

  try {
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        vMethod: 'POST',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: json.customer.emailAddress,
        password: null,
        anonymous: true,
        firstName: json.shippingAddress.firstName,
        lastName: json.shippingAddress.lastName,
        emailOptIn: json.customer.emailOptIn,
        storeCreditAvailable: 0,
      }),
    });
    const responseJsonEnvelope = await response.json();
    return responseJsonEnvelope;
  } catch (error) {
    // todo
    console.log('createCustomerSomehow catch error todo');
  }
}

async function addSavedObjectToUserSomehow(
  type: any,
  storeUrl: string,
  json: any
) {
  const url = `${storeUrl}api/v1/users/current/${type}`;

  try {
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        vMethod: 'POST',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    });
    const responseJsonEnvelope = await response.json();
    return responseJsonEnvelope;
  } catch (error) {
    // todo
    console.log('addSavedObjectToUserSomehow catch error todo');
  }
}

async function submitOrderSomehow(jsonOrder: any) {
  const url = `${jsonOrder.storeUrl}api/v1/orders/${jsonOrder.cartId}`;
  try {
    const response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        vMethod: 'POST',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonOrder),
    });
    const responseJsonEnvelope = await response.json();
    return responseJsonEnvelope;
  } catch (error) {
    console.log('submitOrderSomehow catch error todo');
  }
}
