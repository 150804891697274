import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../models/Root';

const Thanks: React.FC = observer(() => {
  const { checkout } = useMst();

  return (
    <div className="text-center">
      Your cart is empty.
      <div className="mt-6">
        <span className="block w-full rounded-md shadow-sm">
          <a
            href={checkout.storeUrl}
            target="_top"
            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-vol-primary focus:outline-none transition duration-150 ease-in-out"
          >
            Continue Shopping
          </a>
        </span>
      </div>
    </div>
  );
});

export default Thanks;
