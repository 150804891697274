import React from 'react';
import { observer } from 'mobx-react-lite';
import { values } from 'mobx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import { useMst } from '../models/Root';
import Input from './Input';
import Select from './Select';
import Header from './Header';
import Button from './Button';
import Footer from './Footer';
import BackButton from './BackButton';
import PlacesAutocomplete from './PlacesAutocomplete';

const schema = yup.object().shape({
  checkout: yup.object().shape({
    billingAddress: yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      address1: yup.string().required(),
      address2: yup.string().optional(),
      city: yup.string().required(),
      state: yup.string().required(),
      postalCode: yup.string().required(),
      country: yup.string().required(),
    }),
  }),
});

const AddBillTo: React.FC = observer(() => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const { checkout, config } = useMst();
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });
  const mySubmit = (e: any, functionToRunAfterRHF: any) => {
    e.preventDefault();
    // do your early validation here

    if (checkout.state === 'pending') {
      // wait
    } else {
      handleSubmit((json) => {
        functionToRunAfterRHF(json);
      })(e);
    }
  };

  return (
    <div className="form">
      <Header />
      <BackButton goToStep="OneClick" />

      <div className="mt-6 text-sm font-bold">Add New Bill To Address</div>

      {checkout.state === 'notDeliverable' && (
        <div className="rounded-md bg-yellow-100 p-4 mt-3">
          <div className="text-xs leading-5 text-yellow-800">
            {checkout.addressError}
          </div>
        </div>
      )}

      <form onSubmit={(e) => mySubmit(e, checkout.addBillTo)}>
        <div className="mt-3 grid row-gap-0 col-gap-2 grid-cols-6">
          <div className="col-span-3">
            <Input
              label="First name"
              name="checkout.billingAddress.firstName"
              register={register()}
              errors={errors.checkout?.billingAddress?.firstName}
              type="text"
              autoCorrect="off"
              autoComplete="given-name"
            />
          </div>
          <div className="col-span-3">
            <Input
              label="Last name"
              name="checkout.billingAddress.lastName"
              register={register()}
              errors={errors.checkout?.billingAddress?.lastName}
              type="text"
              autoCorrect="off"
              autoComplete="family-name"
            />
          </div>
          <div className="col-span-6 sm:col-span-4">
            <PlacesAutocomplete
              label="Address"
              name="checkout.billingAddress.address1"
              register={register()}
              errors={errors.checkout?.billingAddress?.address1}
              type="text"
              autoCorrect="off"
              autoComplete="address-line1"
            />
          </div>
          {!isMobile && (
            <div className="col-span-6 sm:col-span-2">
              <Select
                label="Country"
                name="checkout.billingAddress.country"
                options={values(config.countries).map(function (country) {
                  return {
                    name: country.Name,
                  };
                })}
                defaultValue="United States"
                register={register()}
                errors={errors.checkout?.billingAddress?.country}
                autoComplete="country"
              />
            </div>
          )}
          <div className="col-span-6">
            <Input
              label="Apartment, suite, etc. (optional)"
              name="checkout.billingAddress.address2"
              register={register()}
              errors={errors.checkout?.billingAddress?.address2}
              type="text"
              autoCorrect="off"
              autoComplete="address-line2"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="City"
              name="checkout.billingAddress.city"
              register={register()}
              errors={errors.checkout?.billingAddress?.city}
              type="text"
              autoCorrect="off"
              autoComplete="address-level2"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="State"
              name="checkout.billingAddress.state"
              register={register()}
              errors={errors.checkout?.billingAddress?.state}
              type="text"
              autoCorrect="off"
              autoComplete="address-level1"
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Zip"
              name="checkout.billingAddress.postalCode"
              register={register()}
              errors={errors.checkout?.billingAddress?.postalCode}
              type="text"
              autoCorrect="off"
              autoComplete="postal-code"
            />
          </div>
          {isMobile && (
            <div className="col-span-6">
              <Select
                label="Country"
                name="checkout.billingAddress.country"
                options={values(config.countries).map(function (country) {
                  return {
                    name: country.Name,
                  };
                })}
                defaultValue="United States"
                register={register()}
                errors={errors.checkout?.billingAddress?.country}
                autoComplete="country"
              />
            </div>
          )}
        </div>

        <Button text="Add Bill To Address" />
        <Footer />
      </form>
    </div>
  );
});

export default AddBillTo;
