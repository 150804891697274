/* eslint import/no-cycle: "off" */
/* eslint no-param-reassign: "off" */
/* eslint @typescript-eslint/no-use-before-define: "off" */
import { types, flow, getSnapshot, getRoot, Instance } from 'mobx-state-tree';
import { IRootStore } from './Root';

export type ITokenization = Instance<typeof Tokenization>;
export const Tokenization = types
  .model({
    FullCardNumber: types.string,
    NumberPart: types.string,
    Last4: types.string,
    CardType: types.string,
    CardTypeName: types.string,
    PersistCard: types.boolean,
    CardHolderName: types.string,
    ExpireMonth: types.string,
    ExpireYear: types.string,
    CVV: types.string,
    Token: types.string,
  })
  .views((self) => ({
    get root(): IRootStore {
      return getRoot(self);
    },
  }))
  .actions((self) => {
    const getToken = flow(function* () {
      // get token
      const response = yield getTokenSomehow(
        self.root.config.payments.pciUrl,
        self.root.config.payments.pciMerchantId,
        getSnapshot(self)
      );
      const responseText = yield response.text();
      console.log('pciaas response', responseText);
      const token = formatToken(responseText);

      // update tokenizatoin model and remove fullcardnumber and CVV
      self.Token = token;
      const redactedCardNumber = `************${self.Last4}`;

      // update checkout model
      const paymentProfile = {
        id: null,
        method: 'Credit Card', // todo: double check
        card: {
          id: null,
          cardNumber: redactedCardNumber,
          pCIaaSId: token,
          holdersName: self.CardHolderName,
          expMonth: self.ExpireMonth,
          expYear: self.ExpireYear,
          last4: self.Last4,
          cardType: self.CardType,
          cardTypeName: self.CardTypeName,
          issueYear: null,
          issueMonth: null,
          issueNumber: null,
          cVV: null,
        },
        preferred: true,
      };

      self.root.checkout.paymentProfile.setPaymentProfileWithToken(
        paymentProfile
      );
    });

    const updateCardType = function (json: any) {
      self.CardType = json.CardType.toString();
      self.CardTypeName = json.CardTypeName;
    };

    const updateTokenization = flow(function* (json: any) {
      self.FullCardNumber = json.tokenization.FullCardNumber;
      self.NumberPart = numberpart(json.tokenization.FullCardNumber); // all except last 4
      self.Last4 = last4(json.tokenization.FullCardNumber); // last 4 only
      self.PersistCard = self.root.config.payments.enableStoredPaymentProfiles;
      self.CardHolderName = json.tokenization.CardHolderName;
      self.ExpireMonth = `0${json.tokenization.ExpireMonth}`.slice(-2); // format with leading zero
      self.ExpireYear = `20${json.tokenization.ExpireYear}`.slice(-4); // format with leading zero
      self.CVV = json.tokenization.CVV;

      return yield getToken();
    });

    return {
      updateTokenization,
      updateCardType,
    };
  });

async function getTokenSomehow(
  pciUrl: string,
  pciMerchantId: string,
  lockedJson: any
) {
  // copy the object so that we can modify it
  const editableJson = JSON.parse(JSON.stringify(lockedJson));

  // don't send FullCardNumber and Last4
  delete editableJson.FullCardNumber;
  delete editableJson.Last4;

  console.log('getTokenSomehow:', editableJson);
  const url = `${pciUrl}/cards`;
  const config = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      MerchantId: pciMerchantId,
    },
    body: JSON.stringify(editableJson),
  };

  try {
    const response = await fetch(url, config);
    if (response.ok) {
      return response;
    }
    return response;
    // todo
  } catch (error) {
    // todo
    return error;
  }
}

function numberpart(FullCardNumber: string) {
  // return without last 4 digits
  const withoutLast4 = FullCardNumber.substring(0, FullCardNumber.length - 4);
  const NumberPart = `${withoutLast4}****`;
  return NumberPart;
}

function last4(FullCardNumber: string) {
  // return only last 4 digits
  const Last4 = FullCardNumber.slice(FullCardNumber.length - 4);
  return Last4;
}

function formatToken(rawTokenResponse: string) {
  return rawTokenResponse.replace(/"/g, '');
}
