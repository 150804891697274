import React from 'react';

type SelectProps = {
  label: string;
  name: string;
  register?: any;
  options?: any;
  defaultValue?: string;
  children?: any;
  autoComplete?: string;
  errors?: any;
  onChange?: any;
};

const Select = ({
  label,
  children,
  name,
  options = [],
  defaultValue,
  register,
  errors,
  ...otherProps
}: SelectProps) => {
  if (options.length > 0) {
    return (
      <div>
        <div className="mt-2">
          <div className="mt-1 rounded-md">
            <label
              htmlFor={name}
              className={`absolute text-xs font-medium leading-2 ml-1 pl-2 mt-1 ${
                errors ? 'text-red-600' : 'text-gray-600'
              }`}
            >
              {label}
            </label>
            <select
              id={name}
              name={name}
              ref={register}
              defaultValue={defaultValue}
              {...otherProps}
              className={`appearance-none block w-full p-3 pt-6 pb-1 ml-0 border bg-gray-200 border-transparent rounded-md placeholder-gray-400 focus:shadow-none focus:outline-none focus:bg-white ${
                errors ? 'border-red-600' : 'focus:border-blue-300'
              } transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
            >
              {options.map((option: any, index: number) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="mt-2">
        <div className="mt-1 rounded-md">
          <label
            htmlFor={name}
            className={`absolute text-xs font-medium leading-2 ml-1 pl-2 mt-1 ${
              errors ? 'text-red-600' : 'text-gray-600'
            }`}
          >
            {label}
          </label>
          <input className="appearance-none block w-full p-3 pt-6 pb-1 ml-0 border bg-gray-200 border-transparent rounded-md placeholder-gray-400 focus:shadow-none focus:outline-none focus:bg-white transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
        </div>
      </div>
    </div>
  );
};

export default Select;
