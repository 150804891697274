import React from 'react';

const ErrorPage: React.FC = () => {
  return (
    <div className="text-center">
      <a className="text-xs underline" href="/shoppingcart.asp" target="_top">
        Return to shopping cart
      </a>
    </div>
  );
};

export default ErrorPage;
