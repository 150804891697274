import React from 'react';
import { observer } from 'mobx-react-lite';
import { values } from 'mobx';
import { useMst } from '../models/Root';
import Header from './Header';
import Footer from './Footer';
import BackButton from './BackButton';

const ChangeShipTo: React.FC = observer(() => {
  const { checkout } = useMst();

  return (
    <div className="form">
      <Header />
      <BackButton goToStep="OneClick" />

      <dd className="mt-6 text-sm leading-5 text-gray-900 sm:mt-6 sm:col-span-2">
        <div className="text-xs font-medium leading-0 pb-2 text-gray-600">
          Ship To
        </div>
        <ul className="border border-gray-200 rounded-md">
          {values(checkout.user.shippingAddresses).map((address, index) => (
            <li
              key={index}
              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 border-t border-gray-200"
            >
              <div className="flex-shrink-0">
                {`${address.firstName} ${address.lastName}`}
                <br />
                {address.address1} <br />
                {address.address2 && (
                  <>
                    {address.address2} <br />
                  </>
                )}
                {`${address.city}, ${address.state} ${address.postalCode}`}{' '}
                <br />
                {address.country}
              </div>
              <button
                type="button"
                onClick={() =>
                  checkout.updateShipTo({
                    checkout: {
                      customer: {
                        emailAddress: checkout.customer.emailAddress, // just keep this as-is to work with existing updateShipTo() function for now
                      },
                      shippingAddress: {
                        firstName: address.firstName,
                        lastName: address.lastName,
                        address1: address.address1,
                        address2: address.address2,
                        city: address.city,
                        state: address.state,
                        postalCode: address.postalCode,
                        country: address.country,
                      },
                    },
                  })
                }
                className="flex py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-vol-primary focus:outline-none transition duration-150 ease-in-out"
              >
                Choose
              </button>
            </li>
          ))}
        </ul>
      </dd>
      <div className="mt-6 mb-6 text-sm">
        or{' '}
        <button
          type="submit"
          className="font-medium text-sm text-vol-primary focus:outline-none focus:underline transition duration-150 ease-in-out"
          onClick={() => checkout.goToStep('AddShipTo')}
        >
          add a new address
        </button>
      </div>
      <Footer />
    </div>
  );
});

export default ChangeShipTo;
