/* eslint import/no-cycle: "off" */
/* eslint @typescript-eslint/interface-name-prefix: "off" */
import { useContext, createContext } from 'react';
import { types, Instance, onSnapshot } from 'mobx-state-tree';
import * as Sentry from '@sentry/react';

import { Checkout, ICheckout } from './Checkout';
import { Tokenization, ITokenization } from './Tokenization';
import { Config, IConfig } from './Config';

// construct the model
export interface IRootStore {
  tokenization: ITokenization;
  checkout: ICheckout;
  config: IConfig;
}
const RootModel = types.model({
  tokenization: Tokenization,
  checkout: Checkout,
  config: Config,
});

// parameters from parent frame
const cartId = new URLSearchParams(`${window.location.search}/`).get('cartId'); // todo: can remove this since we're using cookies now
const getFullSecureStoreUrlFromRegex = (url: string) => {
  const matches = url.match(/^.+?[^/:](?=[?/]|$)/i); // run against regex
  return matches && `${matches[0]}/`; // extract url without the subfolders or pages
};
let storeUrl = getFullSecureStoreUrlFromRegex(document.referrer);
if (!storeUrl) {
  storeUrl = getFullSecureStoreUrlFromRegex(window.location.origin); // this allows checkout to happen directly at /checkout with the iframe
}
if (storeUrl === 'http://localhost:3000/') {
  storeUrl = 'http://www.v65.com/'; // support local development when document.referrer refreshes with live reload and changes to self
}
Sentry.configureScope(function (scope) {
  scope.setTag('storeUrl', `${storeUrl}`);
});

// instantiate the model with default values
export const rootStore = RootModel.create({
  config: {
    enabledState: '',
    logo: {
      text: '',
      url: null,
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      publicId: null,
    },
    colors: {
      primary: '#edf2f7',
    },
    features: {
      enableGuestCheckout: true,
      enableOrderComments: true,
      enableCompanyNameField: true,
      enableEmailOptIn: false,
      enableCoupons: true,
      enableGiftCards: true,
      enableGiftWrap: true,
      requireBillToSameAsShipTo: true,
      enableVolusionLogo: false,
    },
    labels: {
      orderComments: null,
      emailOptIn: null,
    },
    analytics: {
      googleAnalyticsId: null,
    },
    messages: {
      shipTo: null,
      delivery: null,
      pay: null,
      oneClick: null,
      thankYou: null,
      footer: null,
    },
    payments: {
      enabledPaymentMethods: [
        {
          id: 5,
          method: 'Visa',
          methodType: 'Credit Card',
        },
      ],
      isPaypalExpressAvailable: true,
      pciUrl: '',
      pciMerchantId: '',
      enableStoredPaymentProfiles: true,
    },
    currencySymbol: '',
    currentServerDateTime: '',
  },
  tokenization: {
    FullCardNumber: '',
    NumberPart: '',
    Last4: '',
    CardType: '',
    CardTypeName: '',
    PersistCard: true,
    CardHolderName: '',
    ExpireMonth: '',
    ExpireYear: '',
    CVV: '',
    Token: '',
  },
  checkout: {
    customer: {
      id: -1,
      emailAddress: '',
      phoneNumber: '',
      pageName: '',
      storeCredit: 0,
      emailOptIn: true,
    },
    shippingAddress: {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      phoneNumber: '',
      id: null,
      residential: true,
      preferred: null,
    },
    billingAddress: {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      phoneNumber: '',
      preferred: null,
      id: null,
    },
    paypalInfo: {
      paymentStatus: null,
      totalAmount: 0,
      transactionId: null,
      paymentMethodId: -1,
      payerId: null,
      token: null,
      useStoreCredit: false,
    },
    paymentProfile: {
      id: null,
      method: 'Credit Card',
      card: {
        cardType: '',
        pCIaaSId: '',
        holdersName: '',
        expMonth: '',
        issueMonth: '',
        expYear: '',
        id: null,
        last4: '',
        issueNumber: null,
        cardNumber: '',
        cardTypeName: '',
        issueYear: null,
        cVV: '',
      },
      preferred: null,
    },
    shippingMethods: [
      {
        id: 0,
        selected: false,
        sortOrder: '',
        price: 0,
        name: '',
      },
    ],
    discounts: [],
    customFields: [],
    totals: {
      shipping: 0,
      tax1: 0,
      tax2: 0,
      tax3: 0,
      taxTotal: 0,
      kitItems: 0,
      giftCardAmountUsed: 0,
      discounts: 0,
      giftWrap: 0,
      qty: 0,
      items: 0,
      grandTotal: 0,
    },
    misc: {
      comment: '',
      isGift: false,
    },
    items: [
      {
        giftCardToName: null,
        code: '',
        giftCardSendMethod: null,
        name: '',
        giftCardEmailAddress: null,
        giftCardFromName: null,
        taxable: true,
        // avalaraTaxCode: null,
        tax: {
          tax3: 0,
          tax2: 0,
          tax1: 0,
        },
        id: 37512,
        giftWrap: {
          price: 0,
          message: '',
          selected: false,
        },
        qty: 1,
        isGiftWrapAvailable: false,
        imgUrl: '',
        productUrl: '',
        pricing: {
          subtotal: 0,
          recurringPrice: {
            price: 0,
            startPrice: 0,
            thenXMonths: '',
            everyXMonths: '',
            startXMonths: null,
          },
          unitPrice: 0,
        },
        giftCardMessage: null,
      },
    ],
    step: 'ShipTo',
    state: 'pending',
    cartIsExpanded: false,
    cartId,
    storeUrl,
    orderId: 0,
    user: {
      id: 0,
      shippingAddresses: [],
      paymentProfiles: [],
    },
  },
});

// log anytime model values change
onSnapshot(rootStore, (snapshot) => console.log('Snapshot: ', snapshot));

// or we can log specific trees
// onSnapshot(rootStore.checkout, snapshot => console.log("Checkout Snapshot: ", snapshot));
// onSnapshot(rootStore.tokenization, snapshot => console.log("Tokenization Snapshot: ", snapshot));

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const { Provider } = RootStoreContext;
export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
