/* eslint @typescript-eslint/camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
/* eslint jsx-a11y/no-noninteractive-element-interactions: "off" */

import React from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import GoogleAddressParser from './GoogleAddressParser';

type PlacesAutocompleteProps = {
  label?: string;
  name?: string;
  register?: any;
  defaultValue?: any;
  children?: any;
  type?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
  autoCapitalize?: string;
  autoCorrect?: string;
  autoComplete?: string;
  pattern?: string;
  noValidate?: boolean;
  errors?: any;
};

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({
  label,
  children,
  type = 'text',
  name,
  register,
  errors,
  ...otherProps
}: PlacesAutocompleteProps) => {
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    callbackName: 'initMap',
    debounce: 200,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description }: { description: any }) => () => {
    clearSuggestions();
    getGeocode({ address: description })
      .then((results) => {
        const address = new GoogleAddressParser(
          results[0].address_components
        ).result();

        console.log('address: ', address);

        // set each ui value, including address1 in a special way first
        setValue(`${address.street_number} ${address.street_name}`, false);
        (document.getElementById(
          'checkout.shippingAddress.address1'
        ) as HTMLInputElement).value = String(
          `${address.street_number} ${address.street_name}`
        );
        (document.getElementById(
          'checkout.shippingAddress.city'
        ) as HTMLInputElement).value = String(address.city);
        (document.getElementById(
          'checkout.shippingAddress.state'
        ) as HTMLInputElement).value = String(address.state);
        (document.getElementById(
          'checkout.shippingAddress.postalCode'
        ) as HTMLInputElement).value = String(address.postal_code);
        (document.getElementById(
          'checkout.shippingAddress.country'
        ) as HTMLInputElement).value = String(address.country);
      })
      .catch((error) => {
        console.log('😱 Error: ', error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion, index) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={index}
          onClick={handleSelect(suggestion)}
          className="text-gray-900
          cursor-default select-none relative py-1 pl-3 pr-3 text-xs"
        >
          <span className="font-normal block truncate">
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </span>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <div>
        <div className="mt-2">
          <div className="mt-1 rounded-md">
            <label
              htmlFor={name}
              className={`absolute text-xs font-medium leading-2 ml-1 pl-2 mt-1 ${
                errors ? 'text-red-600' : 'text-gray-600'
              }`}
            >
              {label}
            </label>
            <input
              id={name}
              name={name}
              type={type}
              ref={register}
              // value={value}
              onChange={handleInput}
              disabled={!ready}
              {...otherProps}
              className={`appearance-none block w-full p-3 pt-6 pb-1 ml-0 border bg-gray-200 border-transparent rounded-md placeholder-gray-400 focus:outline-none focus:bg-white ${
                errors ? 'border-red-600' : 'focus:border-blue-300'
              } transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
            />
          </div>
        </div>
      </div>
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <div className="absolute mt-1 rounded-md bg-white shadow-lg z-10">
          <ul
            tab-index="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
          >
            {renderSuggestions()}
            <li
              className="text-gray-900
          cursor-default select-none relative py-2 pl-3 pr-3"
            >
              <img
                src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
                alt="Powered by Google"
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default PlacesAutocomplete;
